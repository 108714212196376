import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import { defineEntityStoreV2 } from "@/app/process/service/persistence/EntityStoreV2.ts";
import {
  GetPersonDocument,
  GetPersonWithReferencesDocument,
  PersonDeleteDocument,
  PersonDocument,
  type PersonDto,
  type PersonEto,
} from "@/gql/types";

export const usePersonStoreV2 = defineEntityStoreV2<PersonEto, PersonDto>({
  key: "PersonStoreV2",
  singleViewRouteParam: "personId",
  contextKey: DataTableContextKeys.personList,
  listQuery: GetPersonDocument,
  singleQuery: GetPersonWithReferencesDocument,
  createOrUpdateMutation: PersonDocument,
  deleteMutation: PersonDeleteDocument,
});
