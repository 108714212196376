<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService";
import {
  GenerationStatusEnum,
  useDocumentService,
} from "@/app/process/service/DocumentService";
import { useFileService } from "@/app/process/service/FileService";
import {
  type DocumentGenerationOptionsDto,
  DocumentGenerationOptionsHighlightColor,
} from "@/gql/types";

const props = defineProps<{
  preselectedItem?: string;
}>();

const emit = defineEmits<(event: "update", value: string) => void>();

const documentService = useDocumentService();
const dialogService = useDialogService();
const fileService = useFileService();

const { t } = useI18n();
const chosenItemId = ref<string | undefined>(props.preselectedItem);

const isDialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.GENERATE_DOCUMENT),
);

const closeDialog = () => {
  dialogService.closeDialog(UI_DIALOG.GENERATE_DOCUMENT);
  if (
    documentService.generateStatus === GenerationStatusEnum.SUCCESS ||
    documentService.generateStatus === GenerationStatusEnum.WARN
  ) {
    emit("update", documentService.documentGenerationRun?.id ?? "");
  }
  documentService.resetGeneration();
  chosenItemId.value = undefined;
};

const documentGenerationOptions = ref({
  ignoreReplacementValuesSelected: false,
  replacedValuesHighlightColorSelected: true,
  missingValuesForPlaceholderHandlingPrefixSelected: true,
  missingValuesForPlaceholderHandlingSelectionValue:
    "missingValuesHighlightColorSelected",
  applyIterator: false,
});

const getChosenDocumentGenerationOptionsDto =
  (): DocumentGenerationOptionsDto => {
    const options = documentGenerationOptions.value;
    const generationOptions: DocumentGenerationOptionsDto = {
      replacedValuesHighlightColor: options.replacedValuesHighlightColorSelected
        ? DocumentGenerationOptionsHighlightColor.Yellow
        : undefined,
      missingValuesHighlightColor:
        options.missingValuesForPlaceholderHandlingPrefixSelected &&
        options.missingValuesForPlaceholderHandlingSelectionValue ===
          "missingValuesHighlightColorSelected"
          ? DocumentGenerationOptionsHighlightColor.Red
          : undefined,
      missingValuesFallbackValue:
        options.missingValuesForPlaceholderHandlingPrefixSelected &&
        options.missingValuesForPlaceholderHandlingSelectionValue ===
          "missingValuesFallbackValueSelected"
          ? "XXX"
          : undefined,
      applyIterator:
        !options.ignoreReplacementValuesSelected &&
        !!(chosenItemId.value && options.applyIterator),
    };

    if (options.ignoreReplacementValuesSelected) {
      generationOptions.ignoreReplacementValues =
        options.ignoreReplacementValuesSelected;
      generationOptions.missingValuesHighlightColor =
        DocumentGenerationOptionsHighlightColor.CaeliGreen;
      generationOptions.missingValuesFallbackValue = undefined;
    }

    return generationOptions;
  };
</script>

<template>
  <VDialog v-model="isDialogOpen" maxWidth="600">
    <VCard class="pa-4">
      <VCardTitle class="headline">{{ t("document.generate") }}</VCardTitle>
      <VCardText>
        <p class="mb-4">{{ t("document.generateDialog.description") }}</p>

        <VAutocomplete
          v-model="chosenItemId"
          data-testid="document-entity-select"
          :items="documentService.entityItems"
          :label="documentService.entityTypeLabel"
          :readonly="documentService.generateStatus !== null"
          variant="outlined"
          density="compact"
          itemTitle="text"
          itemValue="value"
          hideDetails
          class="mb-4"
        />
        <p>{{ t("document.generateDialog.generateOptionsTitle") }}</p>
        <VCheckboxBtn
          v-model="documentGenerationOptions.ignoreReplacementValuesSelected"
          class="app-checkbox"
          hideDetails="auto"
          density="compact"
          :label="
            t(
              'document.generateDialog.generateOptions.dontReplacePlaceholderWithValues',
            )
          "
        />
        <VCheckboxBtn
          v-model="
            documentGenerationOptions.replacedValuesHighlightColorSelected
          "
          class="app-checkbox"
          hideDetails="auto"
          density="compact"
          :disabled="documentGenerationOptions.ignoreReplacementValuesSelected"
          :label="
            t('document.generateDialog.generateOptions.highlightReplacedValues')
          "
        />

        <VCheckboxBtn
          v-model="
            documentGenerationOptions.missingValuesForPlaceholderHandlingPrefixSelected
          "
          class="app-checkbox"
          hideDetails="auto"
          density="compact"
          :disabled="documentGenerationOptions.ignoreReplacementValuesSelected"
          :label="
            t('document.generateDialog.generateOptions.missingValuesPrefix')
          "
        />
        <VRadioGroup
          v-model="
            documentGenerationOptions.missingValuesForPlaceholderHandlingSelectionValue
          "
          class="two-choices-group"
          inline
          :disabled="
            documentGenerationOptions.ignoreReplacementValuesSelected ||
            !documentGenerationOptions.missingValuesForPlaceholderHandlingPrefixSelected
          "
        >
          <VRadio
            value="missingValuesHighlightColorSelected"
            hideDetails="auto"
            density="compact"
            :label="
              t(
                'document.generateDialog.generateOptions.highlightMissingValues',
              )
            "
          />
          <VRadio
            value="missingValuesFallbackValueSelected"
            hideDetails="auto"
            density="compact"
            :label="
              t(
                'document.generateDialog.generateOptions.useMissingValuesFallback',
              )
            "
          />
        </VRadioGroup>
        <VCheckboxBtn
          v-model="documentGenerationOptions.applyIterator"
          :disabled="
            !chosenItemId ||
            documentGenerationOptions.ignoreReplacementValuesSelected
          "
          class="app-checkbox"
          hideDetails="auto"
          density="compact"
          :label="t('document.generateDialog.generateOptions.applyIterator')"
        />
        <div
          v-if="documentService.generateStatus === GenerationStatusEnum.SUCCESS"
          class="d-flex align-center mt-4 success-alert"
        >
          <VIcon color="green" class="mr-2" icon="mdi-check-circle" />
          {{
            t("document.generateDialog.success", {
              amount: documentService.documentGenerationRun?.results.length,
            })
          }}
        </div>
        <div
          v-if="documentService.generateStatus === GenerationStatusEnum.WARN"
          class="d-flex align-center mt-4 warn-alert"
        >
          <VIcon color="orange" class="mr-2" icon="mdi-alert" />
          {{
            t("document.generateDialog.warn", {
              amount: documentService.documentGenerationRun?.results.length,
            })
          }}
        </div>
        <div
          v-if="documentService.generateStatus === GenerationStatusEnum.ERROR"
          class="d-flex align-center mt-4 error-alert"
        >
          <VIcon color="red" class="mr-2" icon="mdi-alert" />
          {{
            t("document.generateDialog.error", {
              amount: documentService.documentGenerationRun?.results.length,
            })
          }}
        </div>

        <VTextarea
          v-if="documentService.validationMessages"
          v-model="documentService.validationMessages"
          readonly
          rows="4"
          class="mt-4 enhanced-textarea"
        />
      </VCardText>
      <VCardActions class="d-flex justify-space-between">
        <VBtn
          color="secondary"
          data-testid="document-dialog-close"
          outlined
          @click="closeDialog"
        >
          {{ t("action.close") }}
        </VBtn>
        <VBtn
          v-if="!documentService.generateStatus"
          :loading="documentService.isGenerating"
          color="primary"
          data-testid="generate-document-dialog-button"
          variant="elevated"
          class="ml-auto"
          @click="
            documentService.startGeneration(
              chosenItemId,
              getChosenDocumentGenerationOptionsDto(),
            )
          "
        >
          <template v-if="documentService.isGenerating">
            <VIcon class="mdi mdi-loading mdi-spin mr-2"></VIcon>
          </template>
          {{ t("document.generateDialog.generate") }}
        </VBtn>
        <VBtn
          v-if="
            documentService.generateStatus === GenerationStatusEnum.SUCCESS ||
            documentService.generateStatus === GenerationStatusEnum.WARN
          "
          color="primary"
          variant="elevated"
          class="ml-auto"
          :loading="fileService.isLoading"
          @click="documentService.downloadGeneratedDocument"
        >
          {{
            t("document.generateDialog.download", {
              amount: documentService.documentGenerationRun?.results.length,
            })
          }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<style scoped>
.error-alert {
  background-color: #ffebee;
  border-radius: 4px;
  padding: 10px;
}

.success-alert {
  background-color: #e8f5e9;
  border-radius: 4px;
  padding: 10px;
}

.warn-alert {
  background-color: #fff3e0;
  border-radius: 4px;
  padding: 10px;
}

.two-choices-group :deep(.v-selection-control-group) {
  justify-content: space-evenly;
}
</style>
