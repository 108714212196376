<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  initialValue?: number;
  readonly?: boolean;
  noLabel?: boolean;
}>();

const { t, n } = useI18n();

const editTextField = useTemplateRef("editTextField");

const emits = defineEmits<(event: "update", value: number) => void>();

const valueNumber = ref(props.initialValue ?? 0);
const displayValue = computed(() => n(valueNumber.value));

const focused = ref(false);

function onDisplayTextFieldFocus() {
  focused.value = true;
}

function onEditTextFieldBlur() {
  focused.value = false;
  emits("update", valueNumber.value);
}

watch(
  () => props.initialValue,
  (newValue) => {
    valueNumber.value = newValue ?? 0;
  },
);

watch(
  () => editTextField.value,
  (input) => {
    if (!input) {
      return;
    }

    input.focus();
  },
);
</script>

<template>
  <VTextField
    v-if="!focused"
    v-model="displayValue"
    :label="noLabel ? undefined : t('processes.singleView.outputTypes.NUMBER')"
    class="w-100"
    variant="outlined"
    density="compact"
    step="any"
    hideDetails="auto"
    :readonly="props.readonly"
    @focus="onDisplayTextFieldFocus"
  />
  <VTextField
    v-else
    ref="editTextField"
    v-model.number="valueNumber"
    :label="noLabel ? undefined : t('processes.singleView.outputTypes.NUMBER')"
    class="w-100"
    variant="outlined"
    density="compact"
    type="number"
    step="any"
    hideDetails="auto"
    @blur="onEditTextFieldBlur"
  />
</template>
