<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useDocumentService } from "@/app/process/service/DocumentService.ts";

const props = defineProps<{
  documentParameterId: string;
}>();

const { t } = useI18n();
const documentService = useDocumentService();
</script>

<template>
  <VTooltip location="start" :text="t('action.delete')">
    <template #activator="{ props: activator }">
      <VBtn
        v-bind="activator"
        :title="t('action.delete')"
        data-testid="delete-document-parameter-button"
        variant="plain"
        size="tiny"
        color="error"
        icon="mdi-delete"
        @click.stop.prevent="
          documentService.deleteParameter(props.documentParameterId)
        "
      />
    </template>
  </VTooltip>
</template>
