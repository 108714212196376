<script setup lang="ts">
import { type RouteLocationRaw } from "vue-router";

export interface CButtonProps {
  size?: "small" | "large";
  disabled?: boolean;
  icon?: string;
  severity?:
    | "primary"
    | "secondary"
    | "success"
    | "info"
    | "warn"
    | "help"
    | "danger"
    | "contrast";
  text?: boolean;
  outlined?: boolean;
  label?: string;
  raised?: boolean;
  loading?: boolean;
  rounded?: boolean;
  plain?: boolean;
  to?: RouteLocationRaw;
  iconPos?: "left" | "right";
}

const props = withDefaults(defineProps<CButtonProps>(), {
  size: "small",
  severity: "primary",
  icon: "",
  label: undefined,
  iconPos: "left",
  to: undefined,
});
</script>

<template>
  <PButton
    :disabled
    :icon
    :iconPos
    :label
    :loading
    :outlined
    :severity
    :raised
    :rounded
    :size
    :text
    :plain
    :as="props.to ? 'router-link' : 'button'"
    :to="props.to"
    :pt="{
      root: { class: props.to ? '!no-underline' : undefined },
    }"
  />
</template>
