<script lang="ts" setup>
import { type AutoCompleteCompleteEvent } from "primevue";
import { computed, nextTick, ref } from "vue";

import CButton from "@/app/base/components/button/CButton.vue";
import CCard from "@/app/base/components/card/CCard.vue";
import OptionField from "@/app/process/field/OptionField.vue";
import { usePersonService } from "@/app/process/service/PersonService";
import { type FieldKeyDto, type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  initialValue?: string;
  label?: string;
  readonly?: boolean;
  isInherited?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto | string): void;
  (e: "editing", value: boolean): void;
  (e: "delete"): void;
}>();

const unknownName = "-";

const valueOverrides: Partial<FieldValueDto> = {
  // Old values must be cleared, if eg. the field switched to an option field.
  optionId: null,
  value: {
    valueString: null,
    valueDate: null,
    valueNumber: null,
    valueBoolean: null,
    valueEntityId: null,
    valueJson: null,
  },
};

const personService = usePersonService();

const processOutputPersonId = ref(props.initialValue ?? "");

const isOptionField = computed(
  () => (props.fieldKey?.options?.length ?? 0) > 0,
);

const allPersons = computed(() => personService.getAll());
const filteredPeople = ref(allPersons.value);

const person = computed(() => {
  const id =
    props.fieldValue?.value?.valueEntityId ?? processOutputPersonId.value;
  if (!id) {
    return undefined;
  }
  return personService.getById(id);
});

const personOptions = computed(() =>
  props.fieldKey?.options?.map((option) => {
    const personOption = allPersons.value.find(
      (it) => it.id === option.value?.valueEntityId,
    );
    return {
      id: option.id,
      title: personOption?.name ?? "",
    };
  }),
);

function search(event: AutoCompleteCompleteEvent) {
  const query = event.query;
  filteredPeople.value =
    query.trim().length > 0
      ? allPersons.value.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase()),
        )
      : [...allPersons.value];
}

function emitOptionUpdate(selectedOptionId: string) {
  const personOption = props.fieldKey?.options?.find(
    (option) => option.id === selectedOptionId,
  );
  if (personOption && props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      optionId: personOption.id,
    });
  }
}

async function emitUpdate(
  selectedPersonId?: string | null,
  closeCallback?: () => void,
) {
  if (!selectedPersonId || selectedPersonId.length === 0) {
    return;
  }
  if (props.fieldValue) {
    emit("update", {
      ...props.fieldValue,
      ...valueOverrides,
      value: {
        valueEntityId: selectedPersonId,
      },
    });
  } else {
    processOutputPersonId.value = selectedPersonId;
    emit("update", selectedPersonId);
  }

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
  setTimeout(() => closeCallback?.(), 300);
}
</script>

<template>
  <OptionField
    v-if="isOptionField && props.fieldKey"
    :fieldKey="props.fieldKey"
    :fieldValue="props.fieldValue"
    :options="personOptions"
    :label="props.label"
    @update="emitOptionUpdate"
  />

  <PInplace
    v-else
    :pt="{
      root: { class: 'h-full ' },
      display: { class: 'w-full h-full disabled:opacity-100' },
    }"
    @open="emit('editing', true)"
    @close="emit('editing', false)"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2 mt-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col">
          <p
            class="text-sm mb-1 block font-extrabold text-ellipsis overflow-hidden text-nowrap"
          >
            {{ props.label ?? "-" }}
          </p>

          <p
            class="block text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            {{ person?.name ?? unknownName }}
          </p>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <CCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.label ?? "Person" }}
            </p>
            <div class="flex flex-row">
              <CButton
                v-if="!isInherited || !props.readonly"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                @click="emit('delete')"
              />

              <CButton
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <PAutoComplete
          dropdown
          optionLabel="name"
          size="small"
          data-testid="person-field-multiselect"
          :modelValue="person"
          :suggestions="filteredPeople"
          :virtualScrollerOptions="{
            itemSize: 40,
          }"
          @complete="search"
          @itemSelect="
            (event) => {
              emitUpdate(event.value.id, closeCallback);
            }
          "
        />
      </CCard>
    </template>
  </PInplace>
</template>
