<script setup lang="ts">
import { useI18n } from "vue-i18n";

const props = defineProps<{
  options?: {
    id: string;
    value: string;
  }[];
}>();

const filterModel = defineModel<string | null>({ required: true });

const { t } = useI18n();
</script>

<template>
  <PInputText
    v-if="!options"
    v-model="filterModel"
    class="w-72"
    autofocus
    type="text"
    :placeholder="t('table.filter.placeholder')"
  />

  <PSelect
    v-else-if="options"
    v-model="filterModel"
    class="w-72"
    autofocus
    showClear
    :placeholder="t('table.filter.placeholder')"
    :optionValue="(item) => item.id"
    :optionLabel="(item) => item.value"
    :options="props.options ?? []"
  />
</template>
