<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import CButton from "@/app/base/components/button/CButton.vue";
import CCard from "@/app/base/components/card/CCard.vue";

const grafanaUrl = "/grafana";
const router = useRouter();
const { t } = useI18n();

const isFrameBlocked = ref<boolean>(false);
const iframeRef = ref<HTMLIFrameElement | null>(null);
const iframeProxy = ref<Window | null>(null);

const isLocal =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";
const notSupported = "Local Grafana is currently not supported :(";

function redirectToGrafana() {
  if (!window) {
    return;
  }

  iframeProxy.value = window.open(
    grafanaUrl,
    "_blank",
    "popup=yes,resizable=yes,scrollbars=yes,width=800,height=600",
  );

  const intervalId = setInterval(() => {
    try {
      if (
        iframeProxy.value?.closed === true ||
        iframeProxy.value?.location.href.includes(`${grafanaUrl}/d/`)
      ) {
        console.debug("Grafana login might be successful, reloading page");
        clearInterval(intervalId);
        iframeProxy.value?.close();
        router.go(0);
      }
    } catch (e) {
      console.warn("Grafana-Login not yet ready, checking again..");
    }
  }, 1000);
}

function onIframeLoad() {
  try {
    const contentWindow = iframeRef.value?.contentWindow;

    if (!contentWindow?.document) {
      setFrameBlocked(
        "Could not load Grafana into an iFrame, reload this page manually.",
      );
      return;
    }
  } catch (error) {
    setFrameBlocked("There is an error accessing the Grafana iFrame:", error);
  }
}

function setFrameBlocked(...message: unknown[]) {
  console.warn(message);
  isFrameBlocked.value = true;
}
</script>

<template>
  <div
    v-if="!isLocal"
    class="flex flex-col justify-center items-center h-screen bg-gray-100"
  >
    <CCard
      v-if="isFrameBlocked"
      class="w-96"
      :title="t('dashboard.grafanaRedirect.title')"
      :subtitle="t('dashboard.grafanaRedirect.message')"
    >
      <template #footer>
        <CButton
          fluid
          :label="t('dashboard.grafanaRedirect.buttonLabel')"
          @click="redirectToGrafana"
        />
      </template>
    </CCard>

    <iframe
      v-else
      ref="iframeRef"
      title="Dashboards"
      class="w-full h-full border-none"
      :src="grafanaUrl"
      @load="onIframeLoad"
    />
  </div>

  <div
    v-else
    class="flex flex-col w-full h-1/4 items-center justify-center text-h6"
  >
    <p>{{ notSupported }}</p>
  </div>
</template>
