<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import TextEditor from "@/app/base/form/TextEditor.vue";
import TextField from "@/app/base/form/value/TextField.vue";
import TagsEditCard from "@/app/process/field/TagsEditCard.vue";
import ValueField from "@/app/process/field/ValueField.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import { useMessageService } from "@/app/process/service/MessageService";
import { useMessageServiceV2 } from "@/app/process/service/MessageServiceV2.ts";
import DeleteFieldDialog from "@/app/process/single/DeleteFieldDialog.vue";
import FieldDialog from "@/app/process/single/FieldDialog.vue";
import TopUsedFieldKeysSelector from "@/app/process/single/TopUsedFieldKeysSelector.vue";
import { TitleMode, useTitle } from "@/composables/useTitle";
import { EntityType, type FieldValueDto, type MessageDto } from "@/gql/types";

const props = defineProps<{
  messageId: string;
}>();

const { t } = useI18n();

const messageService = useMessageService();
const messageServiceV2 = useMessageServiceV2();
const fieldService = useFieldService();

useTitle(t("message.message", 1), TitleMode.EDIT);

const { selectedMessage: message } = storeToRefs(messageServiceV2);
const fieldValueSelectedForDeletion = ref<FieldValueDto | undefined>();

const messageFields = computed(() =>
  fieldService.getNonTagFieldValues(props.messageId),
);

function openDeleteFieldDialog(fieldValue: FieldValueDto) {
  fieldValueSelectedForDeletion.value = fieldValue;
  fieldService.openDeleteFieldDialog();
}

async function updateMessage(update: Partial<MessageDto>) {
  if (!message.value) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { created, fields, authorId, ...messageWithoutCreated } = message.value;
  await messageServiceV2.createOrUpdate({
    ...messageWithoutCreated,
    ...update,
  });
}
</script>

<template>
  <VCard
    v-if="message"
    class="activity bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <VCardTitle>
      <template v-if="!props.messageId">{{ t("message.create") }} </template>
      <template v-else>{{ t("message.edit") }}</template>
    </VCardTitle>
    <VCard class="pa-4">
      <VForm>
        <div class="d-flex flex-column ga-4">
          <TagsEditCard
            :entityType="EntityType.Message"
            :entityId="messageId"
            @delete="(id: string) => fieldService.deleteFieldWithInstances(id)"
          />
          <TextField
            :initialValue="message.title ?? undefined"
            :label="t('message.title')"
            data-testid="message-title"
            @update="
              (value) =>
                updateMessage({
                  title: value,
                })
            "
          />
          <TextEditor
            :containerId="messageId"
            :previousContent="message.body ?? undefined"
            :label="t('message.body')"
            data-testid="message-body"
            @saveContent="
              (content: string) => {
                updateMessage({ body: content });
              }
            "
          />
          <div class="d-flex flex-column ga-4">
            <ValueField
              v-for="fieldValue in messageFields"
              :key="fieldValue.id"
              :entityId="messageId"
              :fieldValueId="[fieldValue.id]"
              class="w-100"
              data-testid="entity-field"
              @delete="() => openDeleteFieldDialog(fieldValue)"
              @update="
                (update) => fieldService.createOrUpdateFieldValue(update)
              "
            />
          </div>
          <DeleteFieldDialog
            v-if="
              fieldService.isDeleteFieldDialogOpen &&
              fieldValueSelectedForDeletion
            "
            :entityId="messageId"
            :fieldValueId="fieldValueSelectedForDeletion.id"
            :deleteMessage="
              t('message.deleteFieldText', {
                field:
                  fieldService.getFieldKey(
                    fieldValueSelectedForDeletion.fieldKeyId ?? '',
                  )?.name ?? '',
              })
            "
          />
          <div>
            <VBtn
              variant="outlined"
              color="caeli5"
              prependIcon="mdi-plus"
              data-testid="message-add-field"
              @click="() => fieldService.openFieldDialog()"
              >{{ t("action.addSomething", { name: t("processes.field") }) }}
            </VBtn>
            <FieldDialog
              v-if="fieldService.isFieldDialogOpen"
              :entityId="messageId"
              :entityType="[EntityType.Message]"
            />
            <TopUsedFieldKeysSelector
              :entityId="messageId"
              :fieldKeys="messageService.getTopUsedFieldKeys"
            />
          </div>
        </div>
      </VForm>
    </VCard>
  </VCard>
</template>
