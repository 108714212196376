<script setup lang="ts">
import dayjs from "dayjs";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useEntityEventService } from "@/app/process/service/EntityEventService";
import { EntityEventAction } from "@/gql/types";

const props = defineProps<{
  entityId?: string;
}>();

const { t } = useI18n();
const eventLogService = useEntityEventService();

const DATE_FORMAT = "DD.MM.YYYY HH:mm:ss";

const events = computed(() => {
  if (!props.entityId) {
    return [];
  }

  if (fetchingEvents.value || !tooltipHovered.value) {
    return events.value ?? [];
  }

  return eventLogService.getAll(props.entityId);
});
const eventCreated = computed(() =>
  events.value.find((event) => event.action === EntityEventAction.Created),
);
const eventLastUpdated = computed(() => events.value[events.value.length - 1]);

const tooltipHovered = ref(false);
const fetchingEvents = computed(
  () => !!props.entityId && eventLogService.isLoading(props.entityId),
);
const tooltipText = computed(() => {
  if (eventCreated.value == null && eventLastUpdated.value == null) {
    return getTooltipTextEditDateUnknown();
  }

  return getTooltipTextForKnownEvents();
});

function getTooltipTextEditDateUnknown(): string {
  return t("entityHistory.tooltip.lastEditDateUnknown");
}

function getTooltipTextForKnownEvents(): string {
  const tooltipParts: string[] = [];
  if (eventCreated.value != null) {
    const dateTime = dayjs(eventCreated.value.timestamp).format(DATE_FORMAT);
    tooltipParts.push(t("entityHistory.tooltip.created", { dateTime }));
  }

  if (eventLastUpdated.value != null) {
    const dateTime = dayjs(eventLastUpdated.value.timestamp).format(
      DATE_FORMAT,
    );
    tooltipParts.push(t("entityHistory.tooltip.updated", { dateTime }));
  }

  return tooltipParts.join("\n");
}

function onMouseOver() {
  if (props.entityId == null) {
    return;
  }

  eventLogService.markRefetch(props.entityId);
  eventLogService.getAll(props.entityId);

  tooltipHovered.value = true;
}

function onMouseLeave() {
  tooltipHovered.value = false;
}
</script>

<template>
  <VTooltip :text="tooltipText" contentClass="whitespace-pre-wrap">
    <template #activator="{ props: activator }">
      <VIcon
        v-bind="activator"
        icon="mdi-history"
        @mouseover="onMouseOver()"
        @mouseleave="onMouseLeave()"
      />
    </template>
  </VTooltip>
</template>
