<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { type Component, computed } from "vue";

import AddressFieldCard from "@/app/process/field/AddressFieldCard.vue";
import BooleanFieldCard from "@/app/process/field/BooleanFieldCard.vue";
import DateFieldCard from "@/app/process/field/DateFieldCard.vue";
import FileField from "@/app/process/field/FileField.vue";
import NumberFieldCard from "@/app/process/field/NumberFieldCard.vue";
import PersonFieldCard from "@/app/process/field/PersonFieldCard.vue";
import StringFieldCard from "@/app/process/field/StringFieldCard.vue";
import UrlFieldCard from "@/app/process/field/UrlFieldCard.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import { FieldType, type FieldValueDto } from "@/gql/types";

const props = withDefaults(
  defineProps<{
    fieldValueId: string[];
    targetEntityId?: string;
    readonly?: boolean;
    showLabel?: boolean;
    card?: boolean;
  }>(),
  {
    targetEntityId: undefined,
    showLabel: true,
  },
);

const emit = defineEmits<{
  (e: "delete"): void;
  (e: "update", value: FieldValueDto): void;
}>();

const fieldService = useFieldService();

const fieldValues = computed(() =>
  props.fieldValueId
    .map(fieldService.getFieldValue)
    .filter((value) => value !== undefined),
);

const isInherited = computed(() => {
  if (!props.targetEntityId || fieldValues.value.length === 0) {
    return false;
  }
  return fieldValues.value.every(
    (fieldValue) => fieldValue?.entityId !== props.targetEntityId,
  );
});

const fieldKey = computed(() => {
  if (fieldValues.value.length === 0 || !fieldValues.value[0]) {
    return undefined;
  }
  return fieldService.getFieldKey(fieldValues.value[0].fieldKeyId);
});

function emitUpdate(value: FieldValueDto) {
  // That's the case when eg. a field from an ancestor is overridden in the current entity.
  if (props.targetEntityId && value.entityId !== props.targetEntityId) {
    value.id = uuidv4();
    value.entityId = props.targetEntityId;
  }
  emit("update", value);
}

const componentByType = new Map<FieldType, Component>([
  [FieldType.Date, DateFieldCard],
  [FieldType.Person, PersonFieldCard],
  [FieldType.Address, AddressFieldCard],
  [FieldType.String, StringFieldCard],
  [FieldType.Url, UrlFieldCard],
  [FieldType.Boolean, BooleanFieldCard],
  [FieldType.Number, NumberFieldCard],
  [FieldType.File, FileField],
]);

function emitDelete() {
  emit("delete");
}
</script>

<template>
  <div
    v-if="fieldValues.length > 0 && fieldKey"
    data-testid="entity-field"
    class="w-80"
  >
    <div v-for="fieldValue in fieldValues" :key="fieldValue.id" class="h-full">
      <Component
        :is="componentByType.get(fieldKey.type)"
        v-if="componentByType.has(fieldKey.type)"
        :fieldKey="fieldKey"
        :fieldValue="fieldValue"
        :label="props.showLabel ? fieldKey.name : undefined"
        :readonly="props.readonly"
        :isInherited="isInherited"
        @update="emitUpdate"
        @delete="emitDelete"
      />
    </div>
  </div>
</template>
