import { type EntityType } from "@/gql/types.ts";

export interface TreeNode {
  key: string;
  entityType: EntityType;
  parentId?: string;
  data: {
    name: string;
    entityType: string;
  };
  children: TreeNode[];
}
