<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import CButton from "@/app/base/components/button/CButton.vue";
import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/app/process/list/table/CFilteredDataTable.vue";
import { usePersonServiceV2 } from "@/app/process/service/PersonServiceV2";
import { useTitle } from "@/composables/useTitle";
import { SortDirection } from "@/gql/types.ts";

const { t } = useI18n();
const personService = usePersonServiceV2();

useTitle(t("person.person", 2));

const { availableTags, displayColumns, listRowItems, isLoading } =
  storeToRefs(personService);
</script>

<template>
  <CFilteredDataTable
    class="!p-4"
    stripedRows
    :contextKey="DataTableContextKeys.personList"
    :sortField="personService.mandatoryColumns[0].name"
    :sortDirection="SortDirection.Asc"
    :rowItems="listRowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="personService.mandatoryColumns"
    :isLoading="isLoading"
  >
    <template #additionalControls>
      <CButton
        text
        icon="mdi mdi-plus"
        data-testid="create-person"
        :label="t('person.create')"
        :style="{ whiteSpace: 'nowrap', minWidth: 'auto' }"
        @click="personService.createOrUpdate({ id: uuidv4(), name: 'Neu' })"
      />
    </template>
  </CFilteredDataTable>
</template>
