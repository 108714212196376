import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import { type EntityEventEto, GetEntityEventListDocument } from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual((eto: EntityEventEto) => ({
    id: eto.id,
  })),
  {
    queryName: "entityEvent",
    listQuery: GetEntityEventListDocument,
  },
);

export const useEntityEventStore = defineEntityStore(
  "entityEvent",
  entityProvider,
);
