import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import {
  DocumentGenerationRunDocument,
  type DocumentGenerationRunDto,
  type DocumentGenerationRunEto,
  GetDocumentGenerationRunDocument,
  GetDocumentGenerationRunListDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: DocumentGenerationRunEto) =>
      ({ id: eto.id }) as DocumentGenerationRunDto,
  ),
  {
    queryName: "documentGenerationRun",
    singleQuery: GetDocumentGenerationRunDocument,
    listQuery: GetDocumentGenerationRunListDocument,
    mutation: DocumentGenerationRunDocument,
  },
);

export const useDocumentGenerationRunStore = defineEntityStore(
  "documentGenerationRun",
  entityProvider,
);
