import { PendingPersistedLookupDual } from "@/app/base/utils/IdRelationLookups";
import {
  defineEntityStore,
  EntityProvider,
} from "@/app/process/service/persistence/EntityStore";
import {
  DocumentParameterDeleteDocument,
  DocumentParameterDocument,
  type DocumentParameterDto,
  type DocumentParameterEto,
  GetDocumentParameterDocument,
  GetDocumentParameterListDocument,
} from "@/gql/types";

const entityProvider = new EntityProvider(
  new PendingPersistedLookupDual(
    (eto: DocumentParameterEto) => ({ id: eto.id }) as DocumentParameterDto,
  ),
  {
    queryName: "documentParameter",
    listQuery: GetDocumentParameterListDocument,
    singleQuery: GetDocumentParameterDocument,
    mutation: DocumentParameterDocument,
    deleteMutation: DocumentParameterDeleteDocument,
  },
);

export const useDocumentParameterStore = defineEntityStore(
  "documentParameter",
  entityProvider,
);
