import { type DocumentNode } from "@apollo/client/core";
import { print } from "graphql/index";
import {
  GraphQLClient,
  type RequestInitExtended,
  type Variables,
} from "graphql-request";

import config from "@/config";
import { getAccessToken } from "@/services/auth/msal/accessTokens";
import { UserAuthService } from "@/services/auth/UserAuthService";

const endpoint = config.baseUrl ?? "http://localhost:3000";

async function tokenMiddleware(request: RequestInitExtended<object>) {
  return {
    ...request,
    headers: {
      ...request?.headers,
      "Content-Type": "application/json",
      authorization: `Bearer ${UserAuthService.isAuthEnabled() ? await getAccessToken() : ""}`,
    },
  };
}

export const graphQl = new GraphQLClient(`${endpoint}/graphql`, {
  requestMiddleware: tokenMiddleware,
});

// Utility Functions

export async function rawRequest<T>(
  document: DocumentNode,
  variables: Variables,
): QueryResult<T> {
  const { data, extensions } = await graphQl.rawRequest<T>(
    print(document),
    variables,
  );
  return { data, extensions: extensions as QueryExtensions };
}

// Utility Types

export interface PaginationInfo {
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface QueryExtensions {
  pagination?: PaginationInfo;
}

export interface ResultWithExtensions<T> {
  data: T;
  extensions?: QueryExtensions;
}

export type QueryResult<T> = Promise<ResultWithExtensions<T>>;
