<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import ProcessActivityTable from "@/app/process/list/ProcessActivityTable.vue";
import { useTitle } from "@/composables/useTitle";

const { t } = useI18n();

useTitle(t("processes.activity", 2));
</script>

<template>
  <ProcessActivityTable
    class="!p-4"
    showProcess
    showDetails
    :contextKey="DataTableContextKeys.processActivityList"
  />
</template>
