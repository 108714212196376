<script setup lang="ts">
import { type AutoCompleteCompleteEvent } from "primevue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import CButton from "@/app/base/components/button/CButton.vue";
import { useDialogService } from "@/app/process/service/DialogService.ts";
import { useDocumentGenerationRunService } from "@/app/process/service/DocumentGenerationRunService.ts";
import { useDocumentService } from "@/app/process/service/DocumentService.ts";
import GenerateDocumentDialog from "@/app/process/single/GenerateDocumentDialog.vue";
import { type DocumentEto, EntityType } from "@/gql/types.ts";

const props = defineProps<{
  initialValue?: string;
  readonly?: boolean;
}>();

const { t } = useI18n();

const emits = defineEmits<(event: "update", value: string) => void>();

const dialogService = useDialogService();
const documentService = useDocumentService();
const documentGenerationRunService = useDocumentGenerationRunService();
const router = useRouter();

const allDocuments = computed(() => documentService.getAll());
const generationRun = computed(
  () =>
    props.initialValue &&
    documentGenerationRunService.getById(props.initialValue),
);
const processId = computed(
  () => router.currentRoute.value.params.processId as string,
);

const selectedDocument = ref<DocumentEto | null>(null);
const items = ref(allDocuments.value);

const downloadDocument = async () => {
  if (!generationRun.value) {
    return;
  }
  await documentGenerationRunService.downloadDocuments(generationRun.value.id);
};

const confirmSelection = () => {
  if (!selectedDocument.value) {
    return;
  }
  dialogService.openGenerateDocumentDialog(
    selectedDocument.value.id,
    EntityType.Process,
  );
  selectedDocument.value = null;
};

function search(event: AutoCompleteCompleteEvent) {
  const query = event.query;
  items.value =
    query.trim().length > 0
      ? allDocuments.value.filter((document) =>
          document.name?.toLowerCase().includes(query.toLowerCase()),
        )
      : [...allDocuments.value];
}
</script>

<template>
  <div v-if="generationRun">
    <CButton
      :label="t('document.generateDialog.download')"
      @click="downloadDocument"
    />
  </div>
  <div v-else class="flex flex-row gap-4 align-items-center">
    <PAutoComplete
      v-model="selectedDocument"
      :suggestions="items"
      :placeholder="t('document.field.select')"
      forceSelection
      size="small"
      dropdown
      optionLabel="name"
      @complete="search"
    ></PAutoComplete>
    <CButton :label="t('action.confirmSelection')" @click="confirmSelection" />
  </div>

  <GenerateDocumentDialog
    :preselectedItem="processId"
    @update="(value: string) => emits('update', value)"
  />
</template>
