<script setup lang="ts">
import { useRouter } from "vue-router";

import { type PreviousRoute } from "@/app/base/utils/navigation";

defineProps<{
  previousRoute?: PreviousRoute | null;
  activePath?: string;
}>();

const router = useRouter();
const BACK_BUTTON_HEIGHT = 40;
</script>

<template>
  <VAppBar elevation="0" :height="previousRoute ? BACK_BUTTON_HEIGHT : 0">
    <VBanner v-if="previousRoute" class="py-0 mx-n4 gradient2" lines="one">
      <VBannerActions class="mt-0 justify-start">
        <VBtn
          data-testid="back-button"
          flat
          prependIcon="mdi-chevron-left"
          @click="router.go(-1)"
        >
          {{ previousRoute.label }}
        </VBtn>
        <RouterView name="externalProjectManagementLinks" />
      </VBannerActions>
    </VBanner>
  </VAppBar>
</template>

<style scoped>
.v-toolbar :deep(.v-toolbar__extension) {
  flex-direction: column;
}

.v-banner {
  align-self: stretch;
  border-bottom: none;
  width: unset;
}

.v-banner-actions {
  margin-left: 12px;
  height: calc(v-bind(BACK_BUTTON_HEIGHT) * 1px);
  align-items: center;
}

.v-app-bar.v-toolbar {
  color: rgb(var(--v-theme-caeli2));
  border-bottom: 1px solid rgba(var(--v-theme-caeli6), 0.2);
}
</style>
