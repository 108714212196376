<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import CButton from "@/app/base/components/button/CButton.vue";
import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import CFilteredDataTable from "@/app/process/list/table/CFilteredDataTable.vue";
import { useMessageServiceV2 } from "@/app/process/service/MessageServiceV2.ts";
import { useTitle } from "@/composables/useTitle";
import { SortDirection } from "@/gql/types";

const { t } = useI18n();
const messageService = useMessageServiceV2();

useTitle(t("message.message", 2));

const { availableTags, displayColumns, listRowItems, isLoading } =
  storeToRefs(messageService);
</script>

<template>
  <CFilteredDataTable
    class="!p-4"
    stripedRows
    :contextKey="DataTableContextKeys.messageList"
    :sortField="messageService.mandatoryColumns[0].key"
    :sortDirection="SortDirection.Desc"
    :rowItems="listRowItems"
    :availableTags="availableTags"
    :exposedColumns="displayColumns"
    :mandatoryColumns="messageService.mandatoryColumns"
    :isLoading="isLoading"
  >
    <template #additionalControls>
      <CButton
        text
        icon="mdi mdi-plus"
        data-testid="create-message"
        :label="t('message.create')"
        :style="{ whiteSpace: 'nowrap', minWidth: 'auto' }"
        @click="messageService.createOrUpdate({ id: uuidv4(), title: 'Neu' })"
      />
    </template>
  </CFilteredDataTable>
</template>
