<script lang="ts" setup>
import Column from "primevue/column";
import TreeTable from "primevue/treetable";
import { v4 as uuidv4 } from "uuid";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import { useDialogService } from "@/app/process/service/DialogService.ts";
import { useDocumentService } from "@/app/process/service/DocumentService";
import GenerateDocumentDialog from "@/app/process/single/GenerateDocumentDialog.vue";
import GenerateDocumentVariantDialog from "@/app/process/single/GenerateDocumentVariantDialog.vue";
import { useTitle } from "@/composables/useTitle";

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();
const dialogService = useDialogService();

useTitle(t("document.document", 2));

const isLoading = computed(() => documentService.isLoading());
const treeNodes = computed(() => documentService.getTreeNodes());

const expandedKeys = ref({});
const expandTreeNodeIfDocumentFound = (treeNode, document): boolean => {
  if (treeNode.key === document.id) {
    expandedKeys.value[treeNode.key] = true;
    return true;
  } else {
    const childExpanded = treeNode.children.some((child) =>
      expandTreeNodeIfDocumentFound(child, document),
    );
    if (childExpanded) {
      expandedKeys.value[treeNode.key] = true;
    }
    return childExpanded;
  }
};
watch(
  computed(() => documentService.documentVariantCreationResult),
  (value) => {
    if (value.document) {
      treeNodes.value.forEach((node) =>
        expandTreeNodeIfDocumentFound(node, value.document),
      );
      delete value.document;
    }
  },
);

const initialColumns = [
  {
    key: "name",
    fieldName: "name",
    expander: true,
    displayHeader: t("document.name"),
  },
  {
    key: "entityType",
    fieldName: "entityType",
    expander: false,
    displayHeader: t("document.entityType"),
  },
];

const createDocumentClicked = async () => {
  await router.push({
    name: "documentEdit",
    params: { documentId: uuidv4() },
  });
};

const viewDocument = async (documentId: string) => {
  await router.push({
    name: "documentView",
    params: { documentId },
  });
};

const textColumnClicked = async (documentId: string) => {
  await viewDocument(documentId);
};

const openGenerateDocumentDialogClicked = (slotProps) => {
  dialogService.openGenerateDocumentDialog(
    slotProps.node.key,
    slotProps.node.entityType,
  );
};
const openCreateDocumentVariantDialogClicked = (slotProps) => {
  dialogService.openCreateDocumentVariantDialog(
    slotProps.node.key,
    slotProps.node.entityType,
    slotProps.node.data.name,
  );
};
</script>

<template>
  <VCard
    class="bg-grey-lighten-5 h-screen overflow-auto"
    variant="flat"
    :loading="isLoading"
  >
    <template #loader="{ isActive }">
      <VProgressLinear
        color="caeli5"
        height="4"
        indeterminate
        :active="isActive"
      />
    </template>

    <TreeTable
      v-model:expandedKeys="expandedKeys"
      :value="treeNodes"
      rowHover
      size="small"
    >
      <Column
        v-for="col of initialColumns"
        :key="col.key"
        :expander="col.expander"
      >
        <template #header>
          <p class="pl-1 font-bold text-sm">{{ col.displayHeader }}</p>
        </template>

        <template #body="slotProps">
          <div @click.stop.prevent="textColumnClicked(slotProps.node.key)">
            <p class="text-sm">
              {{ slotProps.node.data[col.fieldName] }}
            </p>
          </div>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <div>
            <VBtn
              data-testid="generate-document-variant-button"
              variant="plain"
              size="tiny"
              icon="mdi-source-branch-plus"
              :title="t('document.generateVariant')"
              @click.stop.prevent="
                openCreateDocumentVariantDialogClicked(slotProps)
              "
            />

            <VBtn
              data-testid="generate-document-button"
              variant="plain"
              size="tiny"
              icon="mdi-play"
              :title="t('document.generate')"
              @click.stop.prevent="openGenerateDocumentDialogClicked(slotProps)"
            />
          </div>
        </template>
      </Column>
    </TreeTable>
  </VCard>

  <VTooltip location="start" :text="t('document.create')">
    <template #activator="{ props }">
      <FloatingActionButton
        v-bind="props"
        icon="mdi-plus"
        primary
        data-testid="create-document"
        @click="createDocumentClicked"
      />
    </template>
  </VTooltip>

  <GenerateDocumentDialog />
  <GenerateDocumentVariantDialog />
</template>
