import { type Component, type StyleValue } from "vue";
import { type RouteLocationRaw } from "vue-router";

import { type FieldType } from "@/gql/types";

export interface RowItem {
  class?: string | Record<string, unknown>;
  style?: StyleValue;
  events?: Record<string, unknown>;
  key: string;
  cells: Record<string, CellContent>;
  tags: string[];
  to?: RouteLocationRaw;
}

export interface CellContent {
  mappedContent?: string | string[];
  content?: string | string[];
  component?: Component;
  type?: FieldType;
  props?: Record<string, unknown>;
  events?: Record<string, unknown>;
  sortValue?: string | number;
  hasMultipleValues?: boolean;
  to?: RouteLocationRaw;
}
