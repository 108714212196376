import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import { defineEntityStoreV2 } from "@/app/process/service/persistence/EntityStoreV2.ts";
import {
  GetMessageDocument,
  MessageDeleteDocument,
  MessageDocument,
  type MessageDto,
  type MessageEto,
} from "@/gql/types";

export const useMessageStoreV2 = defineEntityStoreV2<MessageEto, MessageDto>({
  key: "MessageStoreV2",
  singleViewRouteParam: "messageId",
  contextKey: DataTableContextKeys.messageList,
  listQuery: GetMessageDocument,
  singleQuery: GetMessageDocument,
  createOrUpdateMutation: MessageDocument,
  deleteMutation: MessageDeleteDocument,
});
