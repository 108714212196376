<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import ValueField from "@/app/process/field/ValueField.vue";
import ValueFieldCard from "@/app/process/field/ValueFieldCard.vue";
import { useFieldService } from "@/app/process/service/FieldService";
import DeleteFieldDialog from "@/app/process/single/DeleteFieldDialog.vue";
import { type EntityFieldsCardItem } from "@/app/process/single/EntityFieldsCard.types.ts";
import { type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  entityId: string;
  items: EntityFieldsCardItem[];
  cards?: boolean;
  readonly?: boolean;
}>();

const { t } = useI18n();
const fieldService = useFieldService();

const fieldValueSelectedForDeletion = ref(
  undefined as FieldValueDto | undefined,
);

function openDeleteFieldDialog(fieldValue: FieldValueDto) {
  fieldValueSelectedForDeletion.value = fieldValue;
  fieldService.openDeleteFieldDialog();
}
</script>

<template>
  <div>
    <div
      class="flex justify-start"
      :class="{
        'flex-col gap-4': !cards,
        'flex-row flex-wrap gap-y-4 mt-4': cards,
      }"
    >
      <template v-for="field in items" :key="field.value.id">
        <Component
          :is="cards ? ValueFieldCard : ValueField"
          :targetEntityId="props.entityId"
          :fieldValueId="[field.value.id]"
          :readonly="props.readonly || field.readonly"
          @update="
            (newValue) => fieldService.createOrUpdateFieldValue(newValue)
          "
          @delete="() => openDeleteFieldDialog(field.value)"
        />
      </template>
    </div>

    <DeleteFieldDialog
      v-if="
        fieldService.isDeleteFieldDialogOpen && fieldValueSelectedForDeletion
      "
      :fieldValueId="fieldValueSelectedForDeletion.id"
      :deleteMessage="
        t('fields.delete', {
          field:
            fieldService.getFieldKey(
              fieldValueSelectedForDeletion?.fieldKeyId ?? '',
            )?.name ?? '',
        })
      "
    />
  </div>
</template>
