import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { Action, usePromptService } from "@/app/notification/PromptService";
import { createFieldCells } from "@/app/process/list/FilteredDataTableUtil";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import { useFieldService } from "@/app/process/service/FieldService";
import { useMessageStore } from "@/app/process/service/persistence/message/MessageStore";
import {
  EntityType,
  type FieldKeyEto,
  FieldType,
  GetMessageListDocument,
  type MessageDto,
  type MessageEto,
  useGetFieldKeysTopUsedQuery,
} from "@/gql/types";
import { apolloClient } from "@/plugins/apollo";

export const useMessageService = defineStore("MessageService", () => {
  const messageStore = useMessageStore();
  const promptService = usePromptService();
  const fieldService = useFieldService();
  const router = useRouter();
  const { t } = useI18n();

  const translations = {
    message: {
      title: t("message.title"),
      creationDate: t("message.creationDate"),
    },
  };

  const isDeleteMessageDialogOpen = ref(false);

  const { onResult: onResultTopUsed, loading: loadingTopUsed } =
    useGetFieldKeysTopUsedQuery({
      limit: 3,
      entityType: EntityType.Message,
    });
  onResultTopUsed((result) => {
    if (loadingTopUsed.value) {
      return;
    }
    topUsedFieldKeys.value = result.data.fieldKeysTopUsed;
    fieldService.registerLoadedFieldKeys(result.data.fieldKeysTopUsed);
  });
  const topUsedFieldKeys = ref<FieldKeyEto[]>();

  function openDeleteDialog() {
    isDeleteMessageDialogOpen.value = true;
  }

  function closeDeleteDialog() {
    isDeleteMessageDialogOpen.value = false;
  }

  function deleteAndGoToList(id: string) {
    messageStore.deleteById(id).then(
      () => {
        promptService.success(id, Action.DELETE);
        void router.push({
          name: "messageList",
        });
      },
      (reason) => promptService.failure(id, Action.DELETE, reason),
    );
  }

  function getListRowItems(messages?: MessageEto[]) {
    const results: RowItem[] = [];
    const allMessages = messages ?? messageStore.getAll();
    for (const message of allMessages) {
      const rowItem: RowItem = {
        key: message.id,
        cells: {
          ...createTitleCell(message.title),
          ...createCreationDateCell(message.created),
          ...createFieldCells(message.id),
        },
        tags: fieldService
          .getTagFieldValues(message.id)
          .flatMap(
            (fieldValue) =>
              fieldService.getFieldKey(fieldValue.fieldKeyId)?.name ?? [],
          ),
        events: {
          click: () =>
            router.push({
              name: "messageView",
              params: { messageId: message.id },
            }),
        },
      };

      results.push(rowItem);
    }
    return results;
  }

  function createTitleCell(title: string): Record<string, CellContent> {
    return {
      [translations.message.title]: {
        content: title,
        props: { class: "pointer" },
      },
    };
  }

  function createCreationDateCell(
    created: string,
  ): Record<string, CellContent> {
    return {
      [translations.message.creationDate]: {
        content: created,
        type: FieldType.Date,
        props: { class: "pointer" },
      },
    };
  }

  const listDisplayColumns = computed((): string[] => {
    return [
      translations.message.creationDate,
      translations.message.title,
    ].concat(
      fieldService
        .getNonTagFieldKeys(EntityType.Message)
        .flatMap((key) => (key.name ? [key.name] : []))
        .sort((a, b) => a.localeCompare(b)),
    );
  });

  function createOrUpdate(messageDto: MessageDto) {
    messageStore.createOrUpdate(messageDto).then(
      () => promptService.success(messageDto.id, Action.SAVE, messageDto.title),
      (reason) => promptService.failure(messageDto.id, Action.SAVE, reason),
    );
    return messageDto.id;
  }

  async function getMessageReferenceRowItems(id: string) {
    const allMessages = await apolloClient
      .query<Record<string, MessageEto[]>>({
        query: GetMessageListDocument,
        variables: {
          filter: {
            personId: id,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        return result.data.message;
      });
    return getListRowItems(allMessages);
  }

  return {
    isLoading: (id?: string) => messageStore.isLoading(id),
    getById: (id: string) => messageStore.getById(id),
    getAll: () => messageStore.getAll(),
    getListRowItems,
    getMessageReferenceRowItems,
    createOrUpdate,
    deleteAndGoToList,
    openDeleteDialog,
    closeDeleteDialog,
    isDeleteMessageDialogOpen,
    listDisplayColumns,
    getTopUsedFieldKeys: computed(() => topUsedFieldKeys.value),
    markRefetch: (id: string) => messageStore.markRefetch(id),
  };
});
