<script setup lang="ts">
import { ref } from "vue";

import { useValidators } from "@/app/base/form/validators";

export interface CTextFieldProps {
  initialValue?: string;
  disabled?: boolean;
  label?: string;
  noLabel?: boolean;
  required?: boolean;
  technicalKey?: boolean;
  autofocus?: boolean;
  size?: "small" | "large";
  type?: "text" | "number" | "url" | "date";
}

const props = withDefaults(defineProps<CTextFieldProps>(), {
  initialValue: undefined,
  label: undefined,
  size: "small",
  type: "text",
});
const emits = defineEmits<(event: "update", value: string) => void>();

const validators = useValidators();

const lastEmitted = ref(props.initialValue ?? "");
const valueString = ref(props.initialValue ?? "");

function isValid(value: string): boolean | string {
  if (!props.required) {
    return true;
  }
  return validators.required(value);
}

function isTechnicalKey(value: string): boolean | string {
  if (!props.technicalKey) {
    return true;
  }
  return validators.technicalKey(value);
}

function emitValue() {
  if (
    isValid(valueString.value) !== true ||
    isTechnicalKey(valueString.value) !== true
  ) {
    return;
  }
  if (lastEmitted.value === valueString.value) {
    return;
  }
  emits("update", valueString.value);
  lastEmitted.value = valueString.value;
}
</script>

<template>
  <PFloatLabel variant="on">
    <PInputText
      id="label"
      v-model="valueString"
      fluid
      autocomplete="off"
      :type
      :autofocus
      :size
      :disabled
      @blur="emitValue"
      @keydown.enter="emitValue"
    >
      <slot />
    </PInputText>
    <label v-if="label" for="label">{{ label }}</label>
  </PFloatLabel>
</template>
