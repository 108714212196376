<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useDialogService } from "@/app/process/service/DialogService";
import { useDocumentGenerationRunService } from "@/app/process/service/DocumentGenerationRunService.ts";

const props = defineProps<{
  documentGenerationRunId: string;
}>();

const { t } = useI18n();

const dialogService = useDialogService();
const documentGenerationRunService = useDocumentGenerationRunService();
</script>

<template>
  <div class="d-flex align-center ga-2 py-1">
    <VTooltip
      location="start"
      :text="t('documentGenerationRun.actions.download')"
    >
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          data-testid="download-document-button"
          variant="plain"
          size="tiny"
          icon="mdi-download"
          @click.stop.prevent="
            documentGenerationRunService.downloadDocuments(
              props.documentGenerationRunId,
            )
          "
        />
      </template>
    </VTooltip>
    <VTooltip
      location="start"
      :text="t('documentGenerationRun.actions.viewParameter')"
    >
      <template #activator="{ props: activator }">
        <VBtn
          v-bind="activator"
          data-testid="view-parameter-button"
          variant="plain"
          size="tiny"
          icon="mdi-database-eye"
          @click.stop.prevent="
            dialogService.openDocumentGenerationRunParameterDialog(
              props.documentGenerationRunId,
            )
          "
        />
      </template>
    </VTooltip>
  </div>
</template>
