<script setup lang="ts">
import { computed, reactive, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FloatingActionButton from "@/app/base/button/FloatingActionButton.vue";
import TextEditor from "@/app/base/form/TextEditor.vue";
import TextField from "@/app/base/form/value/TextField.vue";
import { translateEnum } from "@/app/base/utils/i18n";
import DocumentFileField from "@/app/process/field/DocumentFileField.vue";
import DocumentParameterTable from "@/app/process/list/DocumentParameterTable.vue";
import { useDocumentService } from "@/app/process/service/DocumentService";
import { TitleMode, useTitle } from "@/composables/useTitle";
import { type DocumentDto, EntityType } from "@/gql/types";

const props = defineProps<{
  documentId: string;
}>();

const { t } = useI18n();
const router = useRouter();
const documentService = useDocumentService();

const pendingDocument = reactive<DocumentDto>({
  id: props.documentId,
  name: "Neu",
  entityType: null,
  templateFileId: null,
});

const existingDocument = computed(() =>
  documentService.getById(props.documentId),
);

useTitle(
  existingDocument.value?.name ??
    pendingDocument.name ??
    t("document.document", 1),
  TitleMode.EDIT,
);

const entityTypeOptions = computed(() => {
  return Object.values(EntityType).map((value) => ({
    id: value,
    title: translateEnum("entityTypes", value),
  }));
});

const pendingDocumentEntityType = computed({
  get: () =>
    entityTypeOptions.value.find(
      (option) => option.id === pendingDocument.entityType,
    ),
  set: (value) => {
    const entityType = value?.id;
    updatePendingDocument({ entityType });
  },
});

const isValidDocument = computed(() => {
  return Object.entries(pendingDocument).every(([key, value]) => {
    if (key === "description") {
      return true;
    }
    if (key === "iterator") {
      return true;
    }
    return value !== undefined && value !== null && value !== "";
  });
});

watchEffect(() => {
  Object.assign(pendingDocument, documentService.getById(props.documentId));
});

watch([isValidDocument, pendingDocument], async ([isValid]) => {
  // Auto-Save on blur only when the document is valid
  if (!isValid) {
    return;
  }
  await createOrUpdateDocument();
});

function updatePendingDocument(update: Partial<DocumentDto>) {
  Object.assign(pendingDocument, update);
}

async function createOrUpdateDocument(params?: { goBack: boolean }) {
  documentService.createOrUpdate(pendingDocument);

  if (params?.goBack) {
    await router.push({
      name: "documentView",
      params: { documentId: pendingDocument.id },
    });
  }
}

function isDocumentExisting() {
  return documentService.getById(props.documentId) !== undefined;
}
</script>

<template>
  <VCard
    class="activity bg-grey-lighten-5 pa-10 pt-3 h-screen overflow-y-auto text-caeli6"
    variant="flat"
  >
    <VCardTitle>
      {{ t("document.edit") }}
    </VCardTitle>
    <VCard class="pa-4">
      <VForm>
        <div class="d-flex flex-column ga-4">
          <TextField
            :initialValue="pendingDocument.name ?? undefined"
            data-testid="document-name"
            :label="t('document.name')"
            @update="(value) => updatePendingDocument({ name: value })"
          />

          <VAutocomplete
            v-model="pendingDocumentEntityType"
            data-testid="document-entity-type"
            :items="entityTypeOptions"
            :label="t('document.entityType')"
            variant="outlined"
            density="compact"
            returnObject
            hideDetails
          />

          <TextField
            :initialValue="pendingDocument.iterator ?? undefined"
            data-testid="document-iterator"
            :label="t('document.iterator.label')"
            @update="
              (iterator) => updatePendingDocument({ iterator: iterator })
            "
          >
            <VTooltip
              activator="parent"
              openDelay="750"
              maxWidth="300"
              :text="t('document.iterator.tooltip')"
              location="bottom"
            ></VTooltip>
          </TextField>

          <TextEditor
            data-testid="document-description"
            :containerId="props.documentId"
            :label="t('document.description')"
            :previousContent="pendingDocument.description ?? undefined"
            @saveContent="
              (content) => updatePendingDocument({ description: content })
            "
          />

          <DocumentFileField
            data-testid="document-template-file"
            :entityId="pendingDocument.id"
            :fileId="pendingDocument.templateFileId ?? undefined"
            @update="
              (fileId) => updatePendingDocument({ templateFileId: fileId })
            "
          />
        </div>
      </VForm>
    </VCard>
    <DocumentParameterTable
      v-if="isDocumentExisting()"
      :entityId="pendingDocument.id"
      :editable="true"
    />
  </VCard>

  <VTooltip location="start" :text="t('ui.save')">
    <template #activator="{ props: activatorProps }">
      <FloatingActionButton
        v-bind="activatorProps"
        icon="mdi-content-save-outline"
        primary
        data-testid="save-document"
        :disabled="!isValidDocument"
        @click="createOrUpdateDocument({ goBack: true })"
      />
    </template>
  </VTooltip>
</template>
