<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import CButton from "@/app/base/components/button/CButton.vue";
import CCard from "@/app/base/components/card/CCard.vue";
import { type FieldKeyDto, type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "editing", value: boolean): void;
  (e: "delete"): void;
}>();

const { t } = useI18n();

function emitUpdate(value: boolean | null) {
  emit("update", {
    ...props.fieldValue,
    value: {
      ...props.fieldValue.value,
      valueBoolean: value,
    },
  });
}
</script>

<template>
  <PInplace
    :pt="{
      root: { class: 'h-full' },
      display: { class: 'w-full h-full ' },
    }"
    @open="emit('editing', true)"
    @close="emit('editing', false)"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2 mt-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col">
          <p
            class="text-sm mb-1 block font-extrabold text-ellipsis overflow-hidden text-nowrap"
          >
            {{ props.label ?? "-" }}
          </p>

          <div
            class="flex flex-row gap-2 text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            <i
              class="mdi opacity-90"
              :class="
                props.fieldValue?.value?.valueBoolean
                  ? 'mdi-check'
                  : 'mdi-close'
              "
            />
            <p v-if="props.fieldValue?.value?.valueBoolean === true">
              {{ t("fields.booleanTrue") }}
            </p>
            <p v-if="props.fieldValue?.value?.valueBoolean === false">
              {{ t("fields.booleanFalse") }}
            </p>
            <p v-else-if="props.fieldValue?.value?.valueBoolean === undefined">
              {{ "-" }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <CCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.label ?? "-" }}
            </p>
            <div class="flex flex-row">
              <CButton
                v-if="!props.readonly"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                @click="emit('delete')"
              />

              <CButton
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <div class="flex flex-row gap-2">
          <PCheckBox
            data-testid="boolean-field-checkbox"
            binary
            :indeterminate="fieldValue?.value?.valueBoolean === undefined"
            :modelValue="fieldValue?.value?.valueBoolean"
            @update:modelValue="emitUpdate"
          />

          <p v-if="props.fieldValue?.value?.valueBoolean === true">
            {{ t("fields.booleanTrue") }}
          </p>

          <p v-if="props.fieldValue?.value?.valueBoolean === false">
            {{ t("fields.booleanFalse") }}
          </p>
        </div>
      </CCard>
    </template>
  </PInplace>
</template>
