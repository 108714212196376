<script lang="ts" setup>
import { computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";

import CButton from "@/app/base/components/button/CButton.vue";
import CCard from "@/app/base/components/card/CCard.vue";
import CTextField from "@/app/base/form/value/CTextField.vue";
import COptionField from "@/app/process/field/COptionField.vue";
import { type FieldKeyEto, type FieldValueDto } from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyEto;
  fieldValue: FieldValueDto;
  label?: string;
  clearable?: boolean;
  readonly?: boolean;
  isInherited?: boolean;
}>();

const emit = defineEmits<{
  (e: "update", value: FieldValueDto): void;
  (e: "editing", value: boolean): void;
  (e: "delete"): void;
}>();

const { t } = useI18n();
const isOptionField = computed(() => (props.fieldKey.options?.length ?? 0) > 0);

async function emitOptionUpdate(value: string, closeCallback?: () => void) {
  emit("update", { ...props.fieldValue, optionId: value });

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
  setTimeout(() => closeCallback?.(), 300);
}
</script>

<template>
  <PInplace
    :pt="{
      root: { class: 'h-full' },
      display: { class: 'w-full h-full' },
    }"
    @open="emit('editing', true)"
    @close="emit('editing', false)"
  >
    <template #display>
      <div class="flex flex-row items-start gap-2">
        <span
          class="mdi text-[2.3rem] opacity-90 mr-2 mt-2"
          :class="props.fieldKey.prefix ?? 'mdi-help'"
        ></span>

        <div class="flex flex-col">
          <p
            class="block mb-1 text-sm font-extrabold text-ellipsis overflow-hidden"
          >
            {{ props.label ?? "-" }}
          </p>

          <p
            class="block text-base text-ellipsis overflow-hidden"
            data-testid="field-value"
          >
            {{ props.fieldValue?.value?.valueString ?? "-" }}
          </p>
        </div>
      </div>
    </template>

    <template #content="{ closeCallback }">
      <CCard :pt="{ root: { class: 'h-full' } }">
        <template #title>
          <div class="flex flex-row justify-between">
            <p
              class="mt-2 text-sm font-bold block text-nowrap text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {{ props.label ?? "-" }}
            </p>
            <div class="flex flex-row">
              <CButton
                v-tooltip.bottom="{
                  value:
                    !props.readonly && isInherited
                      ? t('processes.fieldCannotDeleteInherited')
                      : '',
                }"
                icon="mdi mdi-delete-outline"
                data-testid="delete-field-button"
                text
                severity="danger"
                :disabled="props.readonly || isInherited"
                @click="emit('delete')"
              />

              <CButton
                icon="mdi mdi-close"
                data-testid="close-field-card-button"
                text
                severity="secondary"
                @click="closeCallback"
              />
            </div>
          </div>
        </template>

        <COptionField
          v-if="isOptionField"
          :fieldKey="props.fieldKey"
          :fieldValue="props.fieldValue"
          :label="props.label"
          itemTitle="valueString"
          @update="(value) => emitOptionUpdate(value, closeCallback)"
        />

        <CTextField
          v-else
          v-focustrap
          data-testid="string-field-input"
          :initialValue="props.fieldValue?.value?.valueString ?? undefined"
          :label="fieldKey?.name ?? label ?? '-'"
          @update="
            (value) => {
              emit('update', {
                ...props.fieldValue,
                value: {
                  valueString: value,
                },
              });
              closeCallback();
            }
          "
        />
      </CCard>
    </template>
  </PInplace>
</template>
