<!--suppress VueUnrecognizedSlot, RequiredAttributes -->
<script setup lang="ts">
import { Background } from "@vue-flow/background";
import { useVueFlow, VueFlow } from "@vue-flow/core";
import { MiniMap } from "@vue-flow/minimap";
import { useStorage } from "@vueuse/core";
import { nextTick, onBeforeMount, ref, watch } from "vue";

import GraphEdge from "@/app/process/graphviewer/GraphEdge.vue";
import GraphNode from "@/app/process/graphviewer/GraphNode.vue";
import { useGraphService } from "@/app/process/graphviewer/GraphViewerService";
import GraphViewerToolbar from "@/app/process/graphviewer/GraphViewerToolbar.vue";
import { useActivityService } from "@/app/process/service/ActivityService.ts";
import { useProcessService } from "@/app/process/service/ProcessService.ts";
import { StorageKeys } from "@/config";

const props = defineProps<{
  rootActivityId: string;
  processId?: string;
}>();

const graphService = useGraphService();
const { viewport } = useVueFlow("vueflow");
const showMiniMap = useStorage(StorageKeys.graph.showMiniMap.key, true);
const useSimpleMode = useStorage(StorageKeys.graph.useSimpleMode.key, true);
const activityService = useActivityService();
const processService = useProcessService();

// TODO: Find a better way to update the graph view when the mode changes
const graphKey = ref<number>(0);

function fetch(rootActivityId: string, processId?: string) {
  graphService.fetchGraph({
    rootActivityId,
    processId,
    useSimpleMode: useSimpleMode.value,
  });
}

onBeforeMount(() => {
  if (props.processId) {
    processService.markProcessRefetch(props.processId);
    processService.getProcess(props.processId);
  }
});

watch(
  [
    () => props.rootActivityId,
    () => props.processId,
    () => activityService.linearizedActivityGraphs,
  ],
  ([newRootActivityId, newProcessId]) => {
    fetch(newRootActivityId, newProcessId);
  },
  { immediate: true },
);

watch(useSimpleMode, async () => {
  fetch(props.rootActivityId, props.processId);
  graphKey.value += 1;
  await nextTick();
});
</script>

<template>
  <VueFlow
    :key="graphKey"
    :modelValue="graphService.model"
    :minZoom="0.1"
    :maxZoom="1.5"
    :defaultViewport="viewport"
  >
    <Background />
    <MiniMap v-if="showMiniMap" position="top-right" />
    <GraphViewerToolbar />

    <template #node-graphNode="nodeProps">
      <GraphNode v-bind="nodeProps" />
    </template>

    <template #edge-graphEdge="edgeProps">
      <GraphEdge v-bind="edgeProps" />
    </template>
  </VueFlow>
</template>

<style>
@import "@vue-flow/core/dist/style.css";
@import "@vue-flow/core/dist/theme-default.css";
@import "@vue-flow/controls/dist/style.css";
</style>
