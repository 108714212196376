<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { VCard } from "vuetify/components";

import { useDialogService } from "@/app/process/service/DialogService";
import { type FieldKeyDto } from "@/gql/types";

const props = defineProps<{
  contextKey: string;
  fieldNames: string[];
  availableTags: FieldKeyDto[];
  fieldValues: Map<string, string[]>;
  grabFocusOnKeydown?: boolean;
}>();

const dialogService = useDialogService();

const filterParameters = computed(() =>
  dialogService.getFilterParameters(props.contextKey),
);

const { t } = useI18n();

function focusGrabber(e: KeyboardEvent) {
  if (!props.grabFocusOnKeydown) {
    return;
  }
  if (document.activeElement?.tagName === "input") {
    return;
  }
  if (
    e.key.length === 1 ||
    (e.key.length > 1 && /[^a-zA-Z0-9]/.test(e.key)) ||
    e.key === "Spacebar"
  ) {
    searchField.value?.focus();
  }
}

onMounted(() => {
  window.addEventListener("keydown", focusGrabber);
});

onUnmounted(() => {
  window.removeEventListener("keydown", focusGrabber);
});

const searchBox = ref<HTMLElement>();
const searchField = ref<HTMLElement>();

const searchBoxWidth = computed(() => searchBox.value?.clientWidth);
const searchBoxPosition = computed(() => {
  return {
    top: searchBox.value?.offsetTop,
    left: searchBox.value?.offsetLeft,
  };
});

const tagNames = computed(() =>
  props.availableTags.flatMap((tag) => tag.name ?? []),
);

const allFieldNames = computed(() =>
  props.fieldNames.slice().sort((a, b) => a.localeCompare(b)),
);

const lastSearchExpression = computed(() => {
  return filterParameters.value.searchTerms.length
    ? filterParameters.value.searchTerms[
        filterParameters.value.searchTerms.length - 1
      ]
    : "";
});

const tagSuggestions = ref<string[]>([]);
const fieldSuggestions = ref<string[]>([]);
const fieldValueSuggestions = ref<string[]>([]);
const suggestionIndex = ref(0);

watch([lastSearchExpression], () => {
  tagSuggestions.value = [];
  fieldSuggestions.value = [];
  fieldValueSuggestions.value = [];

  if (typeof lastSearchExpression.value === "string") {
    const value = lastSearchExpression.value;
    tagSuggestions.value = lastSearchExpression.value
      ? tagNames.value.filter(
          (tagName) =>
            !filterParameters.value.tagFilters.includes(tagName) &&
            tagName.toLowerCase().startsWith(value.toLowerCase()),
        )
      : [];
    fieldSuggestions.value = value
      ? allFieldNames.value.filter(
          (fieldKeyName) =>
            !filterParameters.value.searchText.includes("=") &&
            fieldKeyName.toLowerCase().startsWith(value.toLowerCase()),
        )
      : [];
  } else {
    const complexTerm = lastSearchExpression.value as {
      left: string;
      right: string;
    };
    const fieldName = complexTerm.left;
    const searchValue = complexTerm.right;
    fieldValueSuggestions.value = (
      props.fieldValues.get(fieldName)?.sort((a, b) => a.localeCompare(b)) ?? []
    ).filter(
      (value) =>
        !filterParameters.value.valueFieldFilters.some(
          (existingFilter) =>
            existingFilter.left === fieldName && existingFilter.right === value,
        ),
    );
    if (searchValue) {
      fieldValueSuggestions.value = fieldValueSuggestions.value.filter(
        (value) => value.toLowerCase().startsWith(searchValue.toLowerCase()),
      );
    } else {
      tagSuggestions.value = [];
      fieldSuggestions.value = [];
    }
  }
  suggestionIndex.value = 0;
});

function rejoinSearchTerms(
  terms: (string | { left: string; right: string })[],
) {
  const stringTerms = terms.map((term) => {
    if (typeof term === "string") {
      return term;
    }
    const complexTerm = term as { left: string; right: string };
    const left = complexTerm.left.includes(" ")
      ? `"${complexTerm.left}"`
      : complexTerm.left;
    const right = complexTerm.right.includes(" ")
      ? `"${complexTerm.right}"`
      : complexTerm.right;
    return `${left}=${right}`;
  });
  return stringTerms.join(" ");
}

function fillInValueFieldSuggestion(suggestion: string) {
  dialogService.updateFilterParameters(props.contextKey, {
    searchText: rejoinSearchTerms([
      ...filterParameters.value.searchTerms.slice(
        0,
        filterParameters.value.searchTerms.length - 1,
      ),
      (suggestion.includes(" ") ? `"${suggestion}"` : suggestion) + "=",
    ]),
  });
}

function fillInTagSuggestion(suggestion: string) {
  tagSuggestions.value = tagSuggestions.value.filter(
    (tag) => tag !== suggestion,
  );

  dialogService.updateFilterParameters(props.contextKey, {
    searchText: rejoinSearchTerms([
      ...filterParameters.value.searchTerms.slice(
        0,
        filterParameters.value.searchTerms.length - 1,
      ),
    ]),
    tagFilters: [...filterParameters.value.tagFilters, suggestion],
  });
}

function fillInFieldValueSuggestion(suggestion: string) {
  const lastTerm = filterParameters.value.searchTerms[
    filterParameters.value.searchTerms.length - 1
  ] as {
    left: string;
    right: string;
  };
  dialogService.updateFilterParameters(props.contextKey, {
    searchText: rejoinSearchTerms([
      ...filterParameters.value.searchTerms.slice(
        0,
        filterParameters.value.searchTerms.length - 1,
      ),
    ]),
    valueFieldFilters: [
      ...filterParameters.value.valueFieldFilters,
      {
        left: lastTerm.left,
        right: suggestion,
      },
    ],
  });
}

function fillInSuggestionFromIndex() {
  if (suggestionIndex.value < fieldValueSuggestions.value.length) {
    fillInFieldValueSuggestion(
      fieldValueSuggestions.value[suggestionIndex.value],
    );
  } else if (suggestionIndex.value < tagSuggestions.value.length) {
    fillInTagSuggestion(tagSuggestions.value[suggestionIndex.value]);
  } else if (
    suggestionIndex.value <
    tagSuggestions.value.length + fieldSuggestions.value.length
  ) {
    fillInValueFieldSuggestion(
      fieldSuggestions.value[
        suggestionIndex.value - tagSuggestions.value.length
      ],
    );
  } else {
    console.debug("No suggestion selected");
  }
  suggestionIndex.value = 0;
}

const usedTags = computed(() => {
  return props.availableTags.filter((tag) =>
    filterParameters.value.tagFilters.some(
      (tagFilter) => tag.name === tagFilter,
    ),
  );
});
const usedTagIndices = computed(() => {
  const results: number[] = [];
  usedTags.value.forEach((usedTag) => {
    results.push(
      props.availableTags.findIndex(
        (availableTag) => availableTag.id === usedTag.id,
      ),
    );
  });
  return results;
});

const filtersActive = computed(() => {
  return (
    filterParameters.value.searchText ||
    filterParameters.value.valueFieldFilters.length > 0 ||
    filterParameters.value.tagFilters.length > 0
  );
});

function clearFilters(): void {
  dialogService.updateFilterParameters(props.contextKey, { searchText: "" });
  dialogService.updateFilterParameters(props.contextKey, {
    tagFilters: [],
    valueFieldFilters: [],
  });
}

function updateTagSelection(changedIndices: number[]) {
  const removedIndex = usedTagIndices.value.find(
    (index) => !changedIndices.includes(index),
  );
  if (removedIndex !== undefined) {
    const removedTagName = props.availableTags[removedIndex].name;
    if (removedTagName) {
      dialogService.updateFilterParameters(props.contextKey, {
        tagFilters: filterParameters.value.tagFilters.filter(
          (existing) => existing !== removedTagName,
        ),
      });
    }
    return;
  }
  const addedIndex = changedIndices.find(
    (index) => !usedTagIndices.value.includes(index),
  );
  if (addedIndex !== undefined) {
    const tagName = props.availableTags[addedIndex].name;
    if (tagName) {
      filterParameters.value.tagFilters.push(tagName);
    }
  }
}

function clearMostDetailedLayer() {
  if (
    tagSuggestions.value.length ||
    fieldSuggestions.value.length ||
    fieldValueSuggestions.value.length
  ) {
    tagSuggestions.value = [];
    fieldSuggestions.value = [];
    fieldValueSuggestions.value = [];
  } else if (filterParameters.value.searchText) {
    dialogService.updateFilterParameters(props.contextKey, { searchText: "" });
  } else {
    dialogService.updateFilterParameters(props.contextKey, {
      tagFilters: [],
      valueFieldFilters: [],
    });
  }
}

function showAllSuggestionsOrGoToNext() {
  if (!filterParameters.value.searchText && !fieldSuggestions.value.length) {
    tagSuggestions.value = tagNames.value.filter(
      (tagName) => !filterParameters.value.tagFilters.includes(tagName),
    );
    fieldSuggestions.value = allFieldNames.value;
  } else {
    suggestionIndex.value =
      (suggestionIndex.value + 1) %
      (fieldValueSuggestions.value.length
        ? fieldValueSuggestions.value.length
        : tagSuggestions.value.length + fieldSuggestions.value.length);
  }
}

function showAllSuggestionsOrGoToPrevious() {
  if (
    !fieldValueSuggestions.value.length &&
    !tagSuggestions.value.length &&
    !fieldSuggestions.value.length
  ) {
    tagSuggestions.value = tagNames.value.filter(
      (tagName) => !filterParameters.value.tagFilters.includes(tagName),
    );
    fieldSuggestions.value = allFieldNames.value;
  } else if (fieldValueSuggestions.value.length) {
    suggestionIndex.value =
      (fieldValueSuggestions.value.length + suggestionIndex.value - 1) %
      fieldValueSuggestions.value.length;
  } else {
    suggestionIndex.value =
      (tagSuggestions.value.length +
        fieldSuggestions.value.length +
        suggestionIndex.value -
        1) %
      (tagSuggestions.value.length + fieldSuggestions.value.length);
  }
}
</script>

<template>
  <div
    ref="searchBox"
    class="w-100 px-2 d-flex flex-wrap align-center justify-end border bg-white"
    style="cursor: text"
    @click="() => searchField?.focus()"
  >
    <div class="d-flex align-center mr-auto">
      <VIcon icon="mdi-magnify" color="caeli6" class="mr-1 my-2" />
      <div class="tight w-100">
        <VTextField
          ref="searchField"
          :modelValue="filterParameters.searchText"
          variant="plain"
          class="text-caeli6"
          color="caeli6"
          density="compact"
          hideDetails="auto"
          data-testid="universal-filter-input"
          :style="{
            width: `${(filterParameters.searchText.length + 1) * 0.6}rem`,
          }"
          @update:modelValue="
            (searchText) =>
              dialogService.updateFilterParameters(props.contextKey, {
                searchText,
              })
          "
          @keydown.esc="clearMostDetailedLayer"
          @keydown.down="showAllSuggestionsOrGoToNext"
          @keydown.up="showAllSuggestionsOrGoToPrevious"
          @keydown.enter="fillInSuggestionFromIndex"
        />
      </div>
    </div>
    <VCard
      v-if="
        tagSuggestions.length ||
        fieldSuggestions.length ||
        fieldValueSuggestions.length
      "
      class="d-flex flex-column"
      :style="{
        position: 'absolute',
        top: `${(searchBoxPosition?.top ?? 0) + 34}px`,
        left: `${searchBoxPosition?.left ?? 0}px`,
        'z-index': 10,
        width: `${searchBoxWidth ?? 200}px`,
      }"
    >
      <div
        v-if="tagSuggestions.length"
        class="text-caption px-2 py-1 text-blue-grey-lighten-2 suggestion-header"
      >
        {{ t("processes.tag", 2) }}
      </div>
      <div
        v-for="(tagName, index) in tagSuggestions"
        :key="tagName"
        class="px-2 py-1 text-caeli6 suggestion pointer"
        :class="index === suggestionIndex ? 'active' : undefined"
        @click="() => fillInTagSuggestion(tagName)"
      >
        {{ tagName }}
      </div>
      <div
        v-if="fieldSuggestions.length"
        class="text-caption px-2 py-1 text-blue-grey-lighten-2 suggestion-header"
      >
        {{ t("processes.field", 2) }}
      </div>
      <div
        v-for="(fieldKey, index) in fieldSuggestions"
        :key="fieldKey"
        class="px-2 py-1 text-caeli6 suggestion pointer"
        :class="
          index + tagSuggestions.length === suggestionIndex
            ? 'active'
            : undefined
        "
        @click="() => fillInValueFieldSuggestion(fieldKey)"
      >
        {{ fieldKey }}
      </div>
      <div
        v-if="fieldValueSuggestions.length"
        class="text-caption px-2 pt-1 text-blue-grey-lighten-2"
      >
        {{ t("processes.value", 2) }}
      </div>
      <div
        v-for="(value, index) in fieldValueSuggestions"
        :key="value"
        class="px-2 py-1 text-caeli6 suggestion pointer"
        :class="index === suggestionIndex ? 'active' : undefined"
        @click="fillInFieldValueSuggestion(value)"
      >
        <span v-if="value">{{ value }}</span>
        <span v-else class="text-grey">{{
          t("processes.placeholders.emptyValue")
        }}</span>
      </div>
    </VCard>
    <div class="d-flex flex-wrap ga-1 justify-end my-1">
      <VChip
        v-for="valueFilter in filterParameters.valueFieldFilters"
        :key="valueFilter.left + '=' + valueFilter.right"
        variant="text"
        density="compact"
        class="pl-3 pr-0 py-3"
        color="#666"
      >
        <div class="d-flex align-center">
          {{ valueFilter.left + "=" + valueFilter.right }}
          <VBtn
            variant="plain"
            icon="mdi-close"
            size="tiny"
            class="pa-1"
            @click="
              () =>
                dialogService.updateFilterParameters(props.contextKey, {
                  valueFieldFilters: filterParameters.valueFieldFilters.filter(
                    (existing) =>
                      existing.left !== valueFilter.left ||
                      existing.right !== valueFilter.right,
                  ),
                })
            "
          />
        </div>
      </VChip>
      <VChipGroup
        column
        multiple
        class="text-caeli8 d-flex ga-1"
        style="padding: 0"
        :modelValue="usedTagIndices"
        @update:modelValue="updateTagSelection"
      >
        <VChip
          v-for="fieldKey in availableTags"
          :key="fieldKey.id"
          class="chip-select py-3 ma-0 mr-1 mb-1"
          :color="`#${fieldKey.colorHex ?? '000000'}`"
          variant="tonal"
          density="compact"
          filter
        >
          {{ fieldKey.name }}
        </VChip>
      </VChipGroup>
    </div>
    <VBtn
      v-if="filtersActive"
      data-testid="clear-all-filter"
      icon="mdi-close"
      size="small"
      variant="plain"
      color="caeli6"
      @click="clearFilters"
    />
  </div>
</template>

<style scoped>
.border-field {
  border: 1px solid #ccc;
}

.border-field:hover {
  border: 1px solid #789;
}

.suggestion {
  font-size: 0.85rem;
  border-top: 1px solid #f6f6f6;
}

.suggestion.active {
  background-color: #f6f6ff;
}

.suggestion:hover {
  background-color: #f6f6fa;
}

.suggestion-header {
  border-top: 1px solid #eee;
}
</style>
