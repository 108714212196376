export interface RevisionEntry {
  id: string;
  mid: string;
  archived: boolean;
  revision: number;
  released: boolean;
}

export class PendingPersistedLookupDual<
  PERSISTED extends {
    id: string;
  },
  PENDING extends {
    id: string;
  },
> {
  toPending: (persisted: PERSISTED) => PENDING;

  constructor(toPending: (persisted: PERSISTED) => PENDING) {
    this.toPending = toPending;
  }

  persistedById = new Map<string, PERSISTED>();
  persistedByKey = new Map<string, PERSISTED[]>();

  getAllIds() {
    return [...this.persistedById.keys()];
  }

  getAllPersisted(cacheKey?: string): PERSISTED[] {
    if (cacheKey) {
      return this.persistedByKey.get(cacheKey) ?? [];
    }

    const results: PERSISTED[] = [];
    this.getAllIds().forEach((id) => {
      const entry = this.getPersistedOptional(id);
      if (entry !== undefined) {
        results.push(entry);
      }
    });
    return results;
  }

  setPersisted(items: PERSISTED[], cacheKey?: string) {
    this.setPersistedById(items);
    this.setPersistedByKey(items, cacheKey);
  }

  getPersistedOptional(id: string) {
    return this.persistedById.get(id);
  }

  getOptional(id: string) {
    const persistedOptional = this.getPersistedOptional(id);
    if (persistedOptional) {
      return this.toPending(persistedOptional);
    }
    return undefined;
  }

  /**
   * Gets the pending version for an id if present, otherwise falling back to the persisted version
   * @param id
   * @throws Error if neither a pending nor a persisted item is present
   */
  get(id: string) {
    const entry = this.getOptional(id);
    if (entry === undefined) {
      throw new Error(`No existing entry with the id ${id}`);
    }
    return entry;
  }

  /**
   * Removes an item from all lookups
   * @param id
   */
  remove(id: string) {
    const persistedValue = this.persistedById.get(id);
    if (persistedValue === undefined) {
      return;
    }

    this.persistedById.delete(id);

    const keysToDelete: string[] = [];
    this.persistedByKey.forEach((value, key) => {
      const newValue = value.filter((item) => item !== persistedValue);
      if (newValue.length === 0) {
        keysToDelete.push(key);
      } else {
        this.persistedByKey.set(key, newValue);
      }
    });
    keysToDelete.forEach((key) => this.persistedByKey.delete(key));
  }

  private setPersistedById(items: PERSISTED[]) {
    const individualItems: Iterable<[string, PERSISTED]> = items.map((item) => [
      item.id,
      item,
    ]);

    this.persistedById = new Map([
      ...this.persistedById.entries(),
      ...individualItems,
    ]);
  }

  private setPersistedByKey(items: PERSISTED[], cacheKey?: string) {
    const itemsByKey = items.reduce((acc, item) => {
      const key = cacheKey ?? item.id;
      if (acc.has(key)) {
        acc.get(key)?.push(item);
      } else {
        acc.set(key, [item]);
      }
      return acc;
    }, new Map<string, PERSISTED[]>());

    this.persistedByKey = new Map([
      ...this.persistedByKey.entries(),
      ...itemsByKey.entries(),
    ]);
  }
}
