<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, nextTick, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import CButton from "@/app/base/components/button/CButton.vue";
import { type CMenuItem } from "@/app/base/components/menu/CMenuItem.ts";
import { translateEnum } from "@/app/base/utils/i18n.ts";
import StartProcessDialogAny from "@/app/process/action/start/StartProcessDialogAny.vue";
import {
  createFieldCellsByValues,
  DataTableContextKeys,
} from "@/app/process/list/FilteredDataTableUtil";
import CFilteredDataTable from "@/app/process/list/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/app/process/list/table/CFilteredDataTableUtils.ts";
import { type CellContent, type RowItem } from "@/app/process/list/TableTypes";
import DataTableStatusColumn from "@/app/process/output/DataTableStatusColumn.vue";
import { useActivityService } from "@/app/process/service/ActivityService";
import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService.ts";
import { useFieldService } from "@/app/process/service/FieldService";
import { useProcessService } from "@/app/process/service/ProcessService";
import { RestService } from "@/app/process/service/RestService.ts";
import { useTitle } from "@/composables/useTitle";
import {
  EntityType,
  FieldType,
  type ProcessEto,
  ProcessState,
} from "@/gql/types";
import { SortDirection } from "@/gql/types";

const fieldService = useFieldService();
const activityService = useActivityService();
const processService = useProcessService();
const dialogService = useDialogService();

const { processes, isLoadingComputed } = storeToRefs(processService);

const { t } = useI18n();
const router = useRouter();

useTitle(t("processes.process", 2));

const i18n = {
  processTitle: t("processes.process"),
  processTemplateTitle: t("processes.processTemplate"),
  yes: t("boolean.yes"),
  no: t("boolean.no"),
  progressTitle: t("processes.progress"),
};

const mandatoryColumns: DataTableColumn[] = [
  { key: "name", name: i18n.processTitle, type: FieldType.String },
  {
    key: "activity_name",
    name: i18n.processTemplateTitle,
    type: FieldType.String,
  },
  {
    key: "state",
    name: i18n.progressTitle,
    type: FieldType.String,
    options: Object.values(ProcessState).map((key, index) => ({
      id: index.toString(),
      value: translateEnum("processes.state", key),
    })),
  },
];

const selectedTab = ref(t("processes.listView.goToProcesses"));
const isDownloading = ref<boolean>(false);
const actionsMenuRef = useTemplateRef("actionsMenuRef");
const actionsMenuItems = computed<CMenuItem[]>(() => [
  {
    label: t("processes.startButton.startProcess"),
    icon: "mdi mdi-play",
    command: () => dialogService.openDialog(UI_DIALOG.START_ANY),
  },
  {
    testId: "download-json-data",
    label: t("processes.listView.downloadJSON"),
    icon: "mdi mdi-download",
    command: async () => await downloadProcessListStats(),
  },
]);

const displayColumns = computed<DataTableColumn[]>(() => {
  return [...mandatoryColumns].concat(
    fieldService
      .getNonTagFieldKeys(EntityType.Process)
      .sort((a, b) => a.name.localeCompare(b.name))
      .reverse()
      .map((fieldKey) => {
        return {
          name: fieldKey.name,
          key: fieldKey.key,
          type: fieldKey.type,
        };
      }),
  );
});

const availableTags = computed(() =>
  fieldService.getTagFieldKeys(EntityType.Process),
);

const rowItems = computed((): RowItem[] => {
  return processes.value.map((process) => {
    const cells: Record<string, CellContent> = {
      ...createTitleCell(process),
      ...createTemplateCell(process),
      ...createProgressCell(process),
      ...createFieldCellsByValues(process.fields ?? []),
    };

    return {
      key: process.id,
      cells,
      tags: [],
      to: {
        name: "process",
        params: { processId: process.id },
      },
    };
  });
});

function createTitleCell(process: ProcessEto): Record<string, CellContent> {
  return {
    ["name"]: {
      content: process.name ?? "",
      props: { class: "pointer" },
    },
  };
}

function createTemplateCell(process: ProcessEto): Record<string, CellContent> {
  return {
    ["activity_name"]: {
      content:
        activityService.getActivity(process.startActivityId ?? "undefined")
          ?.name ?? "",
      to: {
        name: "processTemplate",
        params: { rootActivityId: process.startActivityId },
      },
    },
  };
}

function createProgressCell(process: ProcessEto): Record<string, CellContent> {
  return {
    ["state"]: {
      component: DataTableStatusColumn,
      props: {
        state: process.state.toString(),
        outputPresentCount: process.status?.outputPresentCount ?? 0,
        outputAllCount: process.status?.outputAllCount ?? 0,
        enumTranslationPath: "processes.state",
      },
      mappedContent: translateEnum("processes.state", process.state),
      content: Object.values(ProcessState).indexOf(process.state).toString(),
    },
  };
}

async function downloadProcessListStats() {
  isDownloading.value = true;
  try {
    await RestService.downloadProcessListStats();
  } catch (error) {
    console.error(error);
  } finally {
    isDownloading.value = false;
  }
}

async function navigateToProcessTemplateList(value: string) {
  if (value === t("processes.listView.goToTemplates")) {
    await router.push({ name: "processTemplateList" });
  } else {
    await nextTick();
    selectedTab.value = t("processes.listView.goToProcesses");
  }
}
</script>

<template>
  <div class="!p-4">
    <CFilteredDataTable
      v-if="selectedTab === t('processes.listView.goToProcesses')"
      stripedRows
      :contextKey="DataTableContextKeys.processList"
      :sortField="mandatoryColumns[0].key"
      :sortDirection="SortDirection.Asc"
      :rowItems="rowItems"
      :availableTags="availableTags"
      :exposedColumns="displayColumns"
      :mandatoryColumns="mandatoryColumns"
      :isLoading="isLoadingComputed"
    >
      <template #additionalControls>
        <div class="flex flex-row gap-4">
          <PSelectButton
            v-model="selectedTab"
            size="small"
            :options="[
              t('processes.listView.goToProcesses'),
              t('processes.listView.goToTemplates'),
            ]"
            @valueChange="navigateToProcessTemplateList"
          >
            <template #option="{ option, index }">
              <span
                :data-testid="
                  index === 1 ? 'go-to-process-templates' : 'go-to-processes'
                "
                >{{ option }}</span
              >
            </template>
          </PSelectButton>

          <CButton
            data-testid="actions-button"
            icon="mdi mdi-chevron-down"
            iconPos="right"
            :loading="isDownloading"
            :disabled="isDownloading"
            :label="t('processes.singleView.actions.buttonTitle')"
            @click="actionsMenuRef?.toggle($event)"
          />
        </div>

        <PTieredMenu
          ref="actionsMenuRef"
          popup
          :model="actionsMenuItems"
          :pt="{
            item: ({ context }) => ({
              'data-testid': context.item.testId,
            }),
          }"
        />
      </template>
    </CFilteredDataTable>

    <StartProcessDialogAny />
  </div>
</template>
