<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { type CellContent } from "@/app/process/list/TableTypes.ts";
import {
  UI_DIALOG,
  useDialogService,
} from "@/app/process/service/DialogService";
import { useDocumentGenerationRunService } from "@/app/process/service/DocumentGenerationRunService.ts";
import { type DocumentGenerationResult } from "@/gql/types.ts";

const dialogService = useDialogService();
const documentGenerationRunService = useDocumentGenerationRunService();

const { t } = useI18n();

const i18n = {
  title: t("documentGenerationRun.parameterView.title"),
  columnName: t("documentGenerationRun.parameterView.table.columnName"),
  columnValue: t("documentGenerationRun.parameterView.table.columnValue"),
};

const dialogOpen = computed(() =>
  dialogService.isDialogOpen(UI_DIALOG.VIEW_DOCUMENT_GENERATION_RUN_PARAMETER),
);

const closeDialog = () => {
  dialogService.closeDialog(UI_DIALOG.VIEW_DOCUMENT_GENERATION_RUN_PARAMETER);
};

const documentGenerationResults = computed(
  () =>
    documentGenerationRunService.getById(dialogService.dialogEntityId)
      ?.results ?? [],
);

function createRowItems(documentGenerationResult: DocumentGenerationResult) {
  const parsedParameter = JSON.parse(documentGenerationResult.parameter);
  return Object.keys(parsedParameter).map((key) => {
    const cells: Record<string, CellContent> = {
      [i18n.columnName]: {
        content: key,
      },
      [i18n.columnValue]: {
        content: String(parsedParameter[key] ?? ""),
      },
    };

    return {
      key,
      cells,
      tags: [],
    };
  });
}

const columns = [i18n.columnName, i18n.columnValue];
</script>

<template>
  <VDialog v-model="dialogOpen" maxWidth="1200">
    <VCard class="pa-4">
      <VCardTitle class="headline">{{ i18n.title }}</VCardTitle>
      <div v-for="(result, index) in documentGenerationResults" :key="index">
        <h2 v-if="documentGenerationResults.length > 1">
          {{
            t("documentGenerationRun.parameterView.resultTitle", {
              count: index + 1,
            })
          }}
        </h2>
        <FilteredDataTable
          :searchByTextColumns="columns"
          :contextKey="`documentGenerationParameter_${index}`"
          :rowItems="createRowItems(result)"
          :exposedColumns="columns"
          :mandatoryColumns="columns"
          :initialColumns="columns"
          :availableTags="[]"
        >
        </FilteredDataTable>
      </div>
      <VCardActions class="d-flex justify-space-between">
        <VBtn
          color="secondary"
          data-testid="document-dialog-close"
          outlined
          @click="closeDialog"
        >
          {{ t("action.close") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>
