import { type DocumentNode } from "@apollo/client/core";
import { type UseQueryReturnType } from "@tanstack/vue-query";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useFieldService } from "@/app/process/service/FieldService.ts";
import {
  useApplyPaginationInfo,
  useGetListFilter,
} from "@/composables/useDataTableFilter.ts";
import { type Eto } from "@/gql/types.ts";
import {
  graphQl,
  rawRequest,
  type ResultWithExtensions,
} from "@/plugins/graphQlClient.ts";

export interface EntityStoreConfig {
  key: string;
  singleViewRouteParam: string;
  contextKey: string;
  listQuery: DocumentNode;
  singleQuery: DocumentNode;
  createOrUpdateMutation: DocumentNode;
  deleteMutation: DocumentNode;
}

export function defineEntityStoreV2<E extends Eto, D extends { id: string }>(
  config: EntityStoreConfig,
) {
  return defineStore(config.key, () => {
    const fieldService = useFieldService();
    const queryClient = useQueryClient();
    const router = useRouter();
    const { listFilter } = useGetListFilter(config.contextKey);
    const { applyPaginationInfo } = useApplyPaginationInfo(config.contextKey);

    const listKey = `${config.contextKey}List`;
    const singleKey = `${config.contextKey}Single`;

    const selectedEntityId = ref<string | undefined>(undefined);

    const entities = computed(() => listQuery.data.value ?? []);
    const selectedEntity = computed(() => singleQuery.data.value);
    const isLoading = computed<boolean>(
      () =>
        listQuery.isLoading.value ||
        listQuery.isRefetching.value ||
        singleQuery.isLoading.value ||
        singleQuery.isRefetching.value,
    );

    watch(router.currentRoute, setSelectedIdFromRoute, { immediate: true });

    // TODO: Add back staleTime with 15s (make it configurable for e2e tests)
    const listQuery = useQuery({
      queryKey: [listKey, listFilter],
      queryFn: () => fetchAll(),
      placeholderData: keepPreviousData,
    });

    const singleQuery = useQuery({
      queryKey: [singleKey, selectedEntityId],
      queryFn: () => fetchOne(selectedEntityId.value),
      enabled: computed(() => selectedEntityId.value !== undefined),
    });

    const createOrUpdateMutation = useMutation({
      mutationFn: (dto: D) => createOrUpdate(dto),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [listKey] });
        await queryClient.invalidateQueries({
          queryKey: [singleKey, selectedEntityId],
        });
      },
    });

    const deleteMutation = useMutation({
      mutationFn: (id: string) => deleteEntity(id),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [listKey] });
        await queryClient.invalidateQueries({
          queryKey: [singleKey, selectedEntityId],
        });
      },
    });

    async function fetchAll(): Promise<E[]> {
      const result = await rawRequest(config.listQuery, {
        filter: {
          listFilter: {
            ...listFilter.value,
            pagination: {
              pageNumber: listFilter.value.pagination?.pageNumber ?? 0,
              pageSize: listFilter.value.pagination?.pageSize ?? 25,
            },
          },
        },
      });

      applyPaginationInfo(result.extensions?.pagination);

      const resultKey = getResultKey(result, `${config.key}-listQuery`);

      if (!resultKey) {
        return [];
      }

      // TODO: Replace this with our new implementation
      const fieldValues = result.data?.[resultKey]?.flatMap(
        (item) => item?.fields ?? [],
      );
      fieldValues.forEach((fieldValue) =>
        fieldService.getFieldKey(fieldValue?.fieldKeyId),
      );
      fieldService.registerLoadedFieldValues(fieldValues);

      return result.data?.[resultKey];
    }

    async function fetchOne(id: string | undefined): Promise<E | undefined> {
      if (id === undefined) {
        return undefined;
      }

      const result = await rawRequest<E>(config.singleQuery, {
        filter: { ids: [id] },
      });

      const resultKey = getResultKey(result, `${config.key}-singleQuery`);

      if (!resultKey) {
        return undefined;
      }

      const entity = result.data?.[resultKey]?.find((item) => item.id === id);
      fieldService.registerLoadedFieldValues(entity?.fields ?? []);

      return entity;
    }

    async function createOrUpdate(dto: {
      id: string;
      [key: string]: unknown;
    }): Promise<E | undefined> {
      const result = await rawRequest<E>(config.createOrUpdateMutation, {
        dto,
      });

      selectedEntityId.value = dto.id;
      const resultKey = getResultKey(result, `${config.key}-singleQuery`);

      if (!resultKey) {
        return undefined;
      }

      return result.data?.[resultKey] as E;
    }

    async function deleteEntity(id: string): Promise<string> {
      await graphQl.request(config.deleteMutation, { id });
      selectedEntityId.value = undefined;
      return id;
    }

    function getById(id: string): UseQueryReturnType<E | undefined, Error> {
      selectedEntityId.value = id;
      return singleQuery;
    }

    function setSelectedIdFromRoute(): string | undefined {
      const id =
        router.currentRoute.value.params?.[config.singleViewRouteParam];

      if (!id) {
        return undefined;
      }

      const entityId = id as string;

      if (entityId !== selectedEntityId.value) {
        selectedEntityId.value = entityId;
      }

      return entityId;
    }

    function getResultKey(
      result: ResultWithExtensions<unknown>,
      logPrefix?: string,
    ): string | undefined {
      const resultKey = Object.keys(result?.data ?? {})?.[0];

      if (!resultKey) {
        const prefix = logPrefix ? `[${logPrefix}]` : "";
        console.warn(`${prefix}No data found in result, wrong key?`);
      }

      return resultKey;
    }

    return {
      listKey,
      singleKey,
      createOrUpdateMutation,
      deleteMutation,
      isLoading,
      entities,
      selectedEntity,
      getById,
    };
  });
}
