import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import { type ColumnPassThroughOptions } from "primevue";

const caeliTheme = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: "#00CA90",
        },
        secondary: {
          color: "#4C5760",
        },
        warn: {
          color: "#C13232",
        },
      },
    },
  },
});

const columnPt: Record<string, ColumnPassThroughOptions> = {
  column: {
    pcFilterAddRuleButton: {
      icon: { class: "!text-primary" },
      label: { class: "!text-primary" },
    },
  },
};

const globalPt = {
  ...columnPt,
};

export const primeVueConfig = {
  pt: globalPt,
  ripple: true,
  theme: {
    preset: caeliTheme,
    options: {
      darkModeSelector: false,
    },
  },
};
