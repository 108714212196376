import { useDebounceFn } from "@vueuse/core";

import {
  type ActivityOutputDto,
  ActivityOutputType,
  type ProcessOutputDto,
} from "@/gql/types";

export const PROCESS_START_DATE_OUTPUT_NAME = "Startdatum";

function checkNotUndefinedOrNull(value: unknown) {
  return value !== undefined && value !== null;
}

export function isValueProvided(
  activityOutput: ActivityOutputDto,
  processOutput?: ProcessOutputDto,
): boolean {
  const outputValue = processOutput?.value;
  switch (activityOutput.type) {
    case ActivityOutputType.String:
      return checkNotUndefinedOrNull(outputValue?.valueString);
    case ActivityOutputType.File:
      return checkNotUndefinedOrNull(outputValue?.valueEntityId);
    case ActivityOutputType.Document:
      return checkNotUndefinedOrNull(outputValue?.valueEntityId);
    case ActivityOutputType.Boolean:
      return checkNotUndefinedOrNull(outputValue?.valueBoolean);
    case ActivityOutputType.Number:
      return checkNotUndefinedOrNull(outputValue?.valueNumber);
    case ActivityOutputType.Date:
      return checkNotUndefinedOrNull(outputValue?.valueDate);
    case ActivityOutputType.DateTime:
      return checkNotUndefinedOrNull(outputValue?.valueDateTime);
    case ActivityOutputType.Url:
      return checkNotUndefinedOrNull(outputValue?.valueString);
    case ActivityOutputType.Person:
      return checkNotUndefinedOrNull(outputValue?.valueEntityId);
    case ActivityOutputType.Json:
      return checkNotUndefinedOrNull(outputValue?.valueJson);
    default:
      return false;
  }
}

export function compareByName<T extends { name?: string | null }>(a: T, b: T) {
  return (a.name ?? "").localeCompare(b.name ?? "");
}

export function compareBySortOrder<T extends { sortOrder?: number | null }>(
  a: T,
  b: T,
) {
  return (a.sortOrder ?? -1) - (b.sortOrder ?? -1);
}

const iconByOutputType = {
  [ActivityOutputType.String]: "mdi-format-text",
  [ActivityOutputType.File]: "mdi-file",
  [ActivityOutputType.Boolean]: "mdi-thumbs-up-down",
  [ActivityOutputType.Number]: "mdi-numeric",
  [ActivityOutputType.Date]: "mdi-calendar",
  [ActivityOutputType.DateTime]: "mdi-calendar-clock",
  [ActivityOutputType.Url]: "mdi-link",
  [ActivityOutputType.Person]: "mdi-account",
  [ActivityOutputType.Document]: "mdi-file-document-multiple",
  [ActivityOutputType.Json]: "mdi-code-json",
};

export function iconifyType(type: ActivityOutputType) {
  return iconByOutputType[type] ?? "mdi-help";
}

export function uniqueById<T extends { id: string }>(
  item: T,
  index: number,
  self: T[],
) {
  return index === self.findIndex((t) => t.id === item.id);
}

export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export interface ListDebouncerConfig {
  bufferTime: number;
}

export class ListDebouncer<T> {
  private readonly debouncedFunction;
  // This is only undefined because Typescript fails to correctly infer the type
  // This is just for programming safety!
  private entityList: T[] | undefined = Array.of();

  constructor(config?: ListDebouncerConfig) {
    this.debouncedFunction = useDebounceFn((cb) => {
      if (!this.entityList || this.entityList?.length === 0) {
        return;
      }

      cb(this.entityList);

      this.entityList = Array.of();
    }, config?.bufferTime ?? 300);
  }

  add(value: T) {
    this.entityList?.push(value);
  }

  run(cb: (ids: T[]) => void) {
    return this.debouncedFunction(cb);
  }
}

export function withoutTypename<E>(obj: E & { __typename?: string }): E {
  const newObj = { ...obj };
  delete newObj.__typename;
  return newObj;
}
