<script setup lang="ts">
import { computed, nextTick, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import CButton from "@/app/base/components/button/CButton.vue";
import { type CMenuItem } from "@/app/base/components/menu/CMenuItem.ts";
import { deduplicate } from "@/app/base/utils/array";
import CreateProcessTemplateDialog from "@/app/process/action/CreateProcessTemplateDialog.vue";
import DeleteProcessTemplateDialog from "@/app/process/action/DeleteProcessTemplateDialog.vue";
import EditRootTemplateDialog from "@/app/process/action/EditRootTemplateDialog.vue";
import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import ProcessTemplateListEntry from "@/app/process/list/ProcessTemplateListEntry.vue";
import ProcessTemplateRootActions from "@/app/process/list/ProcessTemplateRootActions.vue";
import CFilteredDataTable from "@/app/process/list/table/CFilteredDataTable.vue";
import { type DataTableColumn } from "@/app/process/list/table/CFilteredDataTableUtils.ts";
import { type RowItem } from "@/app/process/list/TableTypes";
import { useActivityService } from "@/app/process/service/ActivityService";
import { useDialogService } from "@/app/process/service/DialogService";
import { useFieldService } from "@/app/process/service/FieldService";
import { compareByName } from "@/app/process/utils";
import { type ActivityDto, EntityType, FieldType } from "@/gql/types";

const { t } = useI18n();
const router = useRouter();

const activityService = useActivityService();
const dialogService = useDialogService();
const fieldService = useFieldService();

const i18n = {
  processTemplateTitle: t("processes.processTemplate"),
  actionsTitle: t("processes.singleView.actions.buttonTitle"),
};

const isLoading = computed(
  () =>
    activityService.isLoading() ||
    fieldService.isLoadingFieldKey() ||
    fieldService.isLoadingFieldValue(),
);

const selectedTab = ref(t("processes.listView.goToTemplates"));
const actionsMenuRef = useTemplateRef("actionsMenuRef");
const actionsMenuItems = computed<CMenuItem[]>(() => [
  {
    testId: "create-process-template",
    label: t("processes.createProcessTemplate"),
    icon: "mdi mdi-plus",
    command: () => dialogService.openCreateTemplateDialog(),
  },
]);

function stateLabel(activity: ActivityDto) {
  const current = activityService.getCurrentVersion(
    activityService.getMid(activity.id) ?? "undefined",
  );
  const lastReleased = activityService.getLastReleased(
    activityService.getMid(activity.id) ?? "undefined",
  );
  if (!lastReleased) {
    return "draft";
  }
  if (lastReleased.id === current?.id) {
    return "released";
  }
  return "draftReleased";
}

const activityChains = computed(() => {
  return activityService.linearizedActivityGraphs
    .concat()
    .sort((a, b) => compareByName(a[0], b[0]))
    .sort((a, b) => stateLabel(a[0]).localeCompare(stateLabel(b[0])));
});

const rootActivityMids = computed((): string[] =>
  deduplicate(
    activityChains.value.flatMap(
      (activityChain) => activityService.getMid(activityChain[0].id) ?? [],
    ),
  ),
);

const mandatoryColumns: DataTableColumn[] = [
  {
    key: "activity_name",
    name: i18n.processTemplateTitle,
    type: FieldType.String,
  },
  {
    key: "actions",
    name: i18n.actionsTitle,
    type: FieldType.String,
    isFilterable: false,
    isSortable: false,
  },
];

const availableTags = computed(() =>
  fieldService.getTagFieldKeys(EntityType.Process),
);

const rowItems = computed(() =>
  rootActivityMids.value.flatMap((rootActivityMid) => {
    const current = activityService.getCurrentVersion(rootActivityMid);
    if (!current) {
      return [];
    }
    const lastReleased = activityService.getLastReleased(rootActivityMid);
    if (
      activityService.isArchived(current.id) &&
      (!lastReleased || activityService.isArchived(lastReleased.id))
    ) {
      return [];
    }
    const result: RowItem = {
      key: rootActivityMid,
      class: { released: Boolean(lastReleased) },
      cells: {
        ["activity_name"]: {
          content: current.name ?? "",
          component: ProcessTemplateListEntry,
          props: {
            rootActivityMid,
          },
        },
        ["actions"]: {
          component: ProcessTemplateRootActions,
          props: {
            class: "justify-end",
            rootActivityMid,
          },
        },
      },
      tags: deduplicate(
        activityChains.value
          .find((activityChain) => activityChain[0].id === current.id)
          ?.flatMap((activity) => fieldService.getActiveTagNames(activity.id)),
      ),
    };
    return result;
  }),
);

async function navigateToProcessList(value: string) {
  if (value === t("processes.listView.goToProcesses")) {
    await router.push({ name: "processList" });
  } else {
    await nextTick();
    selectedTab.value = t("processes.listView.goToTemplates");
  }
}
</script>

<template>
  <div class="!p-4">
    <CFilteredDataTable
      stripedRows
      clientSide
      :contextKey="DataTableContextKeys.processTemplateList"
      :rowItems="rowItems"
      :availableTags="availableTags"
      :mandatoryColumns="mandatoryColumns"
      :exposedColumns="mandatoryColumns"
      :isLoading="isLoading"
    >
      <template #additionalControls>
        <div class="flex flex-row gap-4">
          <PSelectButton
            v-model="selectedTab"
            size="small"
            :options="[
              t('processes.listView.goToProcesses'),
              t('processes.listView.goToTemplates'),
            ]"
            @valueChange="navigateToProcessList"
          >
            <template #option="{ option, index }">
              <span
                :data-testid="
                  index === 1 ? 'go-to-process-templates' : 'go-to-processes'
                "
                >{{ option }}</span
              >
            </template>
          </PSelectButton>

          <CButton
            data-testid="actions-button"
            icon="mdi mdi-chevron-down"
            iconPos="right"
            :label="t('processes.singleView.actions.buttonTitle')"
            @click="actionsMenuRef?.toggle($event)"
          />
        </div>

        <PTieredMenu
          ref="actionsMenuRef"
          popup
          :model="actionsMenuItems"
          :pt="{
            item: ({ context }) => ({
              'data-testid': context.item.testId,
            }),
          }"
        />
      </template>
    </CFilteredDataTable>

    <EditRootTemplateDialog />
    <CreateProcessTemplateDialog />
    <DeleteProcessTemplateDialog />
  </div>
</template>
