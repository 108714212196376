<script lang="ts" setup>
import { type AutoCompleteCompleteEvent } from "primevue";
import { computed, nextTick, ref } from "vue";

import {
  type FieldKeyDto,
  type FieldValueDto,
  type ValueDto,
} from "@/gql/types";

const props = defineProps<{
  fieldKey: FieldKeyDto;
  fieldValue?: FieldValueDto;
  label?: string;
  options?: { id: string }[];
  itemTitle?: keyof ValueDto;
}>();

const emit = defineEmits<(e: "update", value: string) => void>();

const options = computed<{ id: string; title?: string }[]>(() => {
  if (props.options) {
    return getOptionsById().map((id) => {
      return { id, title: id };
    });
  } else if (props.fieldKey.options && props.itemTitle) {
    return getOptionsByFieldKey(props.itemTitle);
  } else {
    return [];
  }
});
const filteredOptions = ref<{ id: string; title?: string }[]>([]);

const chosenOptionId = ref(props.fieldValue?.optionId);
const chosenOption = computed(() => {
  if (props.options) {
    return props.options.find((option) => option.id === chosenOptionId.value);
  }

  if (props.itemTitle) {
    return props.fieldKey.options?.find(
      (option) => option.id === chosenOptionId.value,
    )?.value?.[props.itemTitle];
  }

  return "?";
});

function search(event: AutoCompleteCompleteEvent) {
  const query = event.query;
  filteredOptions.value =
    query.trim().length > 0
      ? options.value.filter((option) =>
          option.title?.toLowerCase().includes(query.toLowerCase()),
        )
      : options.value;
}

async function update(value?: string | null) {
  if (!value || value.length === 0) {
    return;
  }
  chosenOptionId.value = value;
  emit("update", value);

  // This is a workaround to close the inplace component after the selection.
  // For now required until PrimeVue fixes it.
  await nextTick();
}

function getOptionsByFieldKey(
  key: keyof ValueDto,
): { id: string; title?: string }[] {
  return (
    props.fieldKey.options
      ?.filter((option) => option.id !== chosenOptionId.value)
      ?.map((option) => ({
        id: option.id,
        title: option.value ? option.value[key] : "?",
      }))
      .sort((a, b) => a.title.localeCompare(b.title)) ?? []
  );
}

function getOptionsById(): string[] {
  return props.options?.map((option) => option.id) ?? [];
}
</script>

<template>
  <PAutoComplete
    dropdown
    optionLabel="title"
    size="small"
    :modelValue="chosenOption"
    :suggestions="filteredOptions"
    @complete="search"
    @itemSelect="
      (event) => {
        update(event.value.id);
      }
    "
  />
</template>
