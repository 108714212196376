import { DataTableContextKeys } from "@/app/process/list/FilteredDataTableUtil.ts";
import { defineEntityStoreV2 } from "@/app/process/service/persistence/EntityStoreV2.ts";
import {
  DeleteProcessDocument,
  GetProcessDocument,
  GetProcessesListDocument,
  ProcessDocument,
  type ProcessDto,
  type ProcessEto,
} from "@/gql/types";

export const useProcessStoreV2 = defineEntityStoreV2<ProcessEto, ProcessDto>({
  key: "ProcessStoreV2",
  singleViewRouteParam: "processId",
  contextKey: DataTableContextKeys.processList,
  listQuery: GetProcessesListDocument,
  singleQuery: GetProcessDocument,
  createOrUpdateMutation: ProcessDocument,
  deleteMutation: DeleteProcessDocument,
});
