<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import CButton from "@/app/base/components/button/CButton.vue";
import CTextField from "@/app/base/form/value/CTextField.vue";
import DocumentParameterListActions from "@/app/process/list/DocumentParameterListActions.vue";
import FilteredDataTable from "@/app/process/list/FilteredDataTable.vue";
import { type CellContent } from "@/app/process/list/TableTypes.ts";
import { useDocumentService } from "@/app/process/service/DocumentService.ts";
import { type DocumentParameterEto } from "@/gql/types.ts";

const { t } = useI18n();
const documentService = useDocumentService();

const props = defineProps<{
  entityId: string;
  editable: boolean;
}>();

const i18n = {
  nameTitle: t("document.parameterTable.name"),
  descriptionTitle: t("document.parameterTable.description"),
  parameterDefinitionTitle: t("document.parameterTable.definition"),
};

const documentParameters = computed(() =>
  documentService.getParametersForDocument(props.entityId),
);

const rowItems = computed(() => {
  if (!documentParameters.value) {
    return [];
  }

  return documentParameters.value.map((parameter) => {
    const cells: Record<string, CellContent> = {
      ...createNameCell(parameter),
      ...createDescriptionCell(parameter),
      ...createParameterDefinitionCell(parameter),
      ...createActionsCell(parameter),
    };

    return {
      key: parameter.id,
      cells,
      tags: [],
    };
  });
});

function createNameCell(parameter: DocumentParameterEto) {
  if (!props.editable) {
    return {
      [i18n.nameTitle]: {
        content: parameter.name,
      },
    };
  }

  return {
    [i18n.nameTitle]: {
      component: CTextField,
      props: {
        initialValue: parameter.name,
      },
      events: {
        update: (newValue: string) => {
          parameter.name = newValue;
          documentService.createOrUpdateParameter({
            ...parameter,
          });
        },
      },
    },
  };
}

function createDescriptionCell(parameter: DocumentParameterEto) {
  if (!props.editable) {
    return {
      [i18n.descriptionTitle]: {
        content: parameter.description ?? "",
      },
    };
  }

  return {
    [i18n.descriptionTitle]: {
      component: CTextField,
      props: {
        initialValue: parameter.description,
      },
      events: {
        update: (newValue: string) => {
          parameter.description = newValue;
          documentService.createOrUpdateParameter({
            ...parameter,
          });
        },
      },
    },
  };
}

function createParameterDefinitionCell(parameter: DocumentParameterEto) {
  if (!props.editable) {
    return {
      [i18n.parameterDefinitionTitle]: {
        content: parameter.technicalParameter,
      },
    };
  }

  return {
    [i18n.parameterDefinitionTitle]: {
      component: CTextField,
      props: {
        initialValue: parameter.technicalParameter,
      },
      events: {
        update: (newValue: string) => {
          parameter.technicalParameter = newValue;
          documentService.createOrUpdateParameter({
            ...parameter,
          });
        },
      },
    },
  };
}

function createActionsCell(
  parameter: DocumentParameterEto,
): Record<string, CellContent> {
  if (!props.editable) {
    return {};
  }
  return {
    [""]: {
      component: DocumentParameterListActions,
      props: {
        documentParameterId: parameter.id,
        class: "justify-end",
      },
    },
  };
}

const mandatoryColumns = [i18n.nameTitle, ""];
const initialColumns = [
  i18n.nameTitle,
  i18n.descriptionTitle,
  i18n.parameterDefinitionTitle,
];

const addParameter = () => {
  const documentParameterDto = {
    id: uuidv4(),
    name: "Neuer Parameteralias",
    description: "Beschreibung",
    technicalParameter: "technischer.parameter",
    documentId: props.entityId,
  };
  documentService.createOrUpdateParameter(documentParameterDto);
};
</script>

<template>
  <FilteredDataTable
    :searchByTextColumns="[i18n.nameTitle]"
    :contextKey="`documentParameter-${props.entityId}`"
    :rowItems="rowItems"
    :mandatoryColumns="mandatoryColumns"
    :initialColumns="initialColumns"
    :availableTags="[]"
    :hideSearchBar="true"
    class="pt-4"
  >
    <template v-if="editable" #header-right>
      <CButton
        text
        icon="mdi mdi-plus"
        :label="
          t('action.addSomething', {
            name: t('documentGenerationRun.parameterView.table.columnName'),
          })
        "
        data-testid="add-parameter-button"
        @click="addParameter"
      ></CButton>
    </template>
  </FilteredDataTable>
</template>
